import React, { Component } from 'react';
import { default as st } from './style';
import Collapse from "@kunukn/react-collapse";
import TextBlock from "../TextBlock";

export default class Question extends Component {
    render() {
        const
            { active, clickHandler, data } = this.props,
            { title, content } = data,
            activeMod = active ? ' _active' : '';

        return (
            <div className={`${st} animate animate-up`}>
                <div className={`${st}__title-wrap`} onClick={clickHandler}>
                    <div className={`${st}__indicator${activeMod}`} />
                    <h4 className={`${st}__title`}>{title}</h4>
                </div>

                <Collapse className={`${st}__content-wrap collapse-css-transition`} isOpen={active}>
                    <div className={`${st}__content`}>
                        <TextBlock content={content} />
                    </div>
                </Collapse>
            </div>
        );
    }
}

