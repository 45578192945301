import { css } from "emotion";
import { styles, grid, fonts } from "../../constants";
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',

        "&__wrap": {
            paddingTop: grid.default[7],
            paddingBottom: grid.default[7],
            position: 'relative'
        },

        "&__container": {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: ['50%', null, '100%'],
            paddingLeft: [null, null, 80, null, 30],
            paddingRight: [null, null, 80, null, 30],
            margin: '0 auto'
        },

        "&__row-item": {
            width: "100%",
        },

        "&__row-item + &__row-item": {
            marginTop: grid.default[3],
        },

        "&__btn-wrap": {
            width: "100%",
            paddingTop: grid.default[4],

            '&._right': {
                textAlign: 'right'
            }
        },

        "&__bottom": {
            width: ['50%', null, '100%'],
            paddingLeft: [null, null, 80, null, 30, 0],
            paddingRight: [null, null, 80, null, 30, 0],
            marginTop: grid.default[1],
            marginLeft: 'auto',
            marginRight: 'auto',
            ...fonts.p4,
            color: colors.gray3,

            a: {
                color: colors.yellow,

                '&:hover': {
                    textDecoration: 'underline'
                }
            }
        },

        '&__table': {
            display: 'flex',
            flexDirection: [null, null, 'column'],
            alignItems: ['stretch', null, 'flex-start'],
            justifyContent: ['space-between', null, 'flex-start'],
            width: '100%'
        },

        '&__col': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: ['calc(50% - 24px)', null, 'calc(100% - 24px)']
        },

        '&__col + &__col': {
            marginTop: [null, null, 40, null, 30]
        },

        '&__col &': {
            "&__btn-wrap": {
                marginTop: 'auto',
            },
        },

        '&._4-6-container &': {
            "&__container": {
                width: ['calc(100% / 6 * 4)', null, '100%'],
            },
        }
    })
);
