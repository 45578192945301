import React, { Component } from 'react';
import { default as st } from './style';

export default class WorkTime extends Component {
    render() {
        const { list } = this.props;

        return (
            <div className={`${st}`}>
                <div className={`${st}__list`}>
                    {list.map((item, key) => (
                        <span key={key} className={`${st}__work-time-row`}>
                            {item}
                        </span>
                    ))}
                </div>
            </div>
        );
    }
}