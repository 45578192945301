import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',

        '&__input-wrap': {
            position: 'relative',

        },

        '&__input': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
            zIndex: 2
        },

        '&__input:hover ~ &__label': {
            color: colors.y2
        },

        '&__label': {
            ...fonts.b1,
            color: colors.yellow,
            position: 'relative',
            display: 'block',
            transition: styles.transitions.default,
            zIndex: 1
        },

        '&__placeholder': {
            ...fonts.p4,
            color: colors.gray3,
            position: 'relative',
            display: 'block',
            zIndex: 1
        },

        '&__file-preview': {
            position: 'relative',
            maxWidth: '100%',
            paddingRight: [22],
        },

        '&__file-name-wrap': {
            display: 'block',
            overflow: 'hidden'
        },

        '&__file-name': {
            ...fonts.b1,
            color: colors.dark,
            whiteSpace: 'nowrap'
        },

        '&__remove-button': {
            ...styles.absolute(0,0,0,'auto'),
            padding: [3],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',

            '&:hover': {
                '&::before': {
                    color: colors.carrot,
                }
            },

            '&::before': {
                content: '"\\e90b"',
                display: 'block',
                fontFamily: styles.fontFamily.icomoon,
                fontSize: [12],
                lineHeight: 1,
                color: colors.yellow,
                transition: styles.transitions.default
            }
        },
    })
);
