import { css } from 'emotion';
import { styles, grid } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        marginBottom: grid.default[8],

        '&__col': {
            '&_left': {
                paddingTop: [grid.default[5][0], grid.default[5][1], 0]
            },
            '&_right': {
                paddingTop: [grid.default[8][0], grid.default[8][1], grid.default[8][2], grid.default[8][3], grid.default[8][4], 0],
                minHeight: [850, 0]
            }
        },

        '&__form-wrap': {
            width: ['calc(100% / 6 * 4)', null, '75%', null, null, '100%'],
            maxWidth: [null, null, 380, null, null, 'none'],
            marginLeft: 'auto'
        },

        '&__bg-wrap': {
            ...styles.absolute(0,'auto','auto',0),
            width: ['50%', null, null, null, '35%'],
            height: '100%'
        },

        '&__stair': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            zIndex: 0,

            svg: {
                position: 'relative',
                display: 'block',
                width: '100%',
                height: 'auto',
                strokeDasharray: 910,
                strokeDashoffset: 910,
                transition: '3s linear',
            },

            '&._draw': {
                svg: {
                    transition: '5s linear',
                    strokeDashoffset: 0
                },
            }
        },
    })
);
