import { actions } from '../constants';

export const removeCalcData = (val) => dispatch => {
    dispatch({
        type: actions.calc.REMOVE_CALC_DATA,
        payload: val
    });
};

export const setCalcData = (val) => dispatch => {
    dispatch({
        type: actions.calc.SET_CALC_DATA,
        payload: val
    });
};

export const resetCalcData = (val) => dispatch => {
    dispatch({
        type: actions.calc.RESET_CALC_DATA,
        payload: val
    });
};
