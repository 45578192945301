import { css } from 'emotion';
import { styles, grid } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        paddingTop: grid.default[8],
        paddingBottom: grid.default[8],
        marginBottom: grid.default[7],
        background: styles.colors.dark,
        color: colors.white,

        '&__reset': {

        },

        '&__reset-wrap': {
            position: 'relative',
            paddingTop: 20,
            zIndex: 20
        },

        '&__table': {
            alignItems: 'stretch',
        },

        '&__col': {
            '&_left': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
            },

            '&_right': {
                display: 'flex',
                flexDirection: [null, null, 'column'],
                justifyContent: 'flex-start',
                alignItems: ['stretch', null, 'flex-start'],
                marginTop: [null, null, 50, null, 40],
            }
        },

        '&__rows-container': {
            width: ['calc(100% / 7 * 4.5)', '100%'],
            marginBottom: [null, null, 50, null, 40]
        },

        '&__total-container': {
            width: 'calc(100% / 7 * 2.5)',
            paddingLeft: [20],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },

        '&__total-wrap': {
            width: '100%',
            flex: '1 0 0',
        },

        '&__total': {
            position: 'relative',
            width: '100%',
            height: 300,
            background: 'red',
        },
    })
);
