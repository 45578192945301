import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        marginBottom: grid.default[8],


        '&__button-wrap': {
            marginTop: grid.default[2]
        },

        '&__title': {
            ...fonts.h1,

            '.suffix-wrap': {
                display: ['inline', null, null, 'block'],
                paddingLeft: ['0.3em', null, null, 0]
            },

            '.suffix-text': {
                ...fonts.h3,
                color: colors.gray2,
                display: 'inline-block'
            }
        },

        '&__bottom': {
            marginTop: grid.default[2],
            display: 'flex',
            flexDirection: [null, null, null, 'column'],
            alignItems: 'flex-start',
            justifyContent: ['space-between', null, null, 'flex-start'],
            width: ['100%', 'calc(100% / 12 * 10)', null, 'calc(100% / 6 * 4)', '100%'],
            paddingLeft: [null, null, null, null, 30],
            paddingRight: [null, null, null, null, 30],
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        },

        '&__icon-subtitle': {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: ['0 30px', null, '0 20px', 0],
            marginBottom: [null, null, null, 20],
            width: ['calc(100% / 3)', null, null, '100%'],

            '&:last-child': {
                marginBottom: 0
            },

            ':before': {
                content: '""',
                display: ['block', null, null, 'none'],
                ...styles.absolute(0, 'auto'),
                width: 3,
                height: 20,
                background: styles.colors.gray2
            },

            p: {
                display: 'block',
                position: 'relative',
                paddingLeft: [30, null, 20],
                ...fonts.p2,
                width: ['calc(100% - 50px)', null, 'calc(100% - 45px)']
            }
        },

        '&__icon-wrap': {
            display: 'block',
            position: 'relative',
            width: [50, null, 45],
            height: [50, null, 45],

            img: {
                display: 'block',
                position: 'relative',
                maxWidth: '100%',
                maxHeight: '100%',
                marginRight: 'auto'
            },
        },

        '&__container': {
            // display: 'block',
            position: 'relative',
            // zIndex: 2
            display: 'flex',
            alignItems: 'stretch',
            paddingTop: grid.default[3],
            minHeight: [800, 700, 0],

            ':before': {
                content: '""',
                display: 'block',
                ...styles.absolute('0', 'auto', 'auto'),
                width: ['calc(100% / 12 * 8)', null, '100%'],
                height: ['100%', null, 700, 500, 320],
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: [`url(${require('../../images/bg/intro-bg.jpg')})`, null, null, null, `url(${require('../../images/bg/intro-bg_700.jpg')})`, `url(${require('../../images/bg/intro-bg_500.jpg')})`],

            },
        },

        '&__inner-container': {
            paddingTop: [styles.headerHeight[0], styles.headerHeight[1], 600, 400, 250],
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'stretch',

        },

        '&__content': {
            display: 'block',
            position: 'relative',
            width: ['40%', null, '100%'],
            margin: ['auto 0 auto auto', null, '0 auto'],
            paddingTop: [40, null, 0],
            paddingBottom: [40, null, 30]
        }
    })
);
