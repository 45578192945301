import React, { Component } from 'react';
import { default as st } from './style';
import TitleBlock from "../../components/TitleBlock";
import InView from "react-intersection-observer";
import LinksGalleryList from "../../components/LinksGalleryList";
import MediaQuery from "react-responsive";
import styles from "../../constants/styles";

export default class Instagram extends Component {
    drawBgText = (text) => {
        let delay = 300;

        return text.split('').map((item, key) => {
            const inlineStyle = {
                transitionDelay: `${delay}ms`
            };

            delay += 150;

            return (
                <span key={key} style={inlineStyle}>{item}</span>
            )
        })
    }

    renderBgText = (text) => {
        return (
            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div ref={ref} className={`${st}__bg-text-wrap`}>
                            <div className={`${st}__bg-text${inViewMod}`}>{this.drawBgText(`@${text}`)}</div>
                        </div>
                    )
                }}
            </InView>
        )
    }

    render() {
        const
            { title, link, list } = this.props,
            { text, url } = link;

        return (
            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div ref={ref} className={`${st}${inViewMod}`}>
                            <div className={`${st}__container container`}>
                                <div className={`${st}__inner-container inner-container _right`}>
                                    <div className={`${st}__table grid-table`}>
                                        <div className={`${st}__col ${st}__col_left col _3-11`}>
                                            <div className={'left-col-container'}>
                                                <TitleBlock title={title} iconLink={{
                                                    icon: 'icon-instagram',
                                                    text: text,
                                                    url: url
                                                }} />
                                            </div>

                                            <MediaQuery minWidth={`${styles.breakpoints[1300]+1}px`}>
                                                {this.renderBgText(text)}
                                            </MediaQuery>
                                        </div>
                                        <div className={`${st}__col ${st}__col_right col _8-11`}>
                                            {list && <LinksGalleryList list={list} />}

                                            <MediaQuery maxWidth={`${styles.breakpoints[1300]}px`}>
                                                {this.renderBgText(text)}
                                            </MediaQuery>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>
        );
    }
}

