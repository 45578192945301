import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        display: 'inline-block',
        ...fonts.b1,
        color: colors.yellow,
        transition: styles.transitions.default,
        cursor: 'pointer',
        marginBottom: [20, null, 10],

        '&:last-child': {
            marginBottom: 0
        },

        '&:hover': {
            color: colors.carrot
        }
    })
);
