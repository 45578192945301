import { css } from 'emotion';
import { styles, grid, fonts, common } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',

        '&__table': {
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between'
        },

        '&__col': {
            '&_left': {
                paddingTop: [grid.default[5][0], grid.default[5][1], 0],
            }
        },

        '&__map-container': {
            display: 'block',
            position: 'relative',
            width: '100%',
            height: ['100%', null, 470, 374, 310],
            minHeight: [500, null, 0],
            marginBottom: [null, null, 40, null, 30],
            background: styles.colors.gray1,

            iframe: {
                display: 'block',
                position: 'relative',
                width: '100%',
                height: '100%',
            }
        },

        '&__contacts-list': {
            ...fonts.p2,
            color: colors.dark,
            maxWidth: 350
        },

        '&__row': {
            marginBottom: grid.default[2],

            '&:last-child': {
                marginBottom: 0
            }
        },

        '&__tutmee-logo': {
            marginTop: grid.default[4]
        },

        '&__email': {
            ...common.linkStyle
        },

        '&__bottom': {
            paddingTop: grid.default[3],
            paddingBottom: grid.default[3],
        },

        '&__copyright': {
            ...fonts.p4,
            color: colors.gray2
        }
    })
);
