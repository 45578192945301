import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        width: '100%',
        height: '100%',

        '.slick-arrow': {
            width: [60, null, 50, null, 40],
            height: [100, null, 80, null, 60],
            background: 'rgba(69,56,56,.4)',
            transition: styles.transitions.default,
            zIndex: 10,

            '&.slick-disabled': {
                opacity: 0,
                pointerEvents: 'none'
            },

            '&:hover': {
                background: 'rgba(69,56,56,.6)',
            },

            '&::before, &::after': {
                content: '""',
                ...styles.absolute(),
                fontSize: 0,
                lineHeight: 0,
                width: '60%',
                height: 3,
                background: colors.white,
                // background: colors.yellow,
                opacity: 1
            },

            '&.slick-prev': {
                left: 0,
                right: 'auto',

                '&::before, &::after': {
                    transformOrigin: '1px 50%'
                },

                '&::before': {
                    transform: 'rotate(-45deg)'
                },

                '&::after': {
                    transform: 'rotate(45deg)'
                },
            },
            '&.slick-next': {
                left: 'auto',
                right: 0,

                '&::before, &::after': {
                    transformOrigin: 'calc(100% - 1px) 50%'
                },

                '&::before': {
                    transform: 'rotate(-45deg)'
                },

                '&::after': {
                    transform: 'rotate(45deg)'
                },
            }
        },

        '&__arrow': {
            width: [40],
            height: [40],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            background: colors.dark,

            '&::before': {
                display: 'block',
                fontFamily: styles.fontFamily.icomoon,
                fontSize: [13],
                lineHeight: '1',
                color: colors.yellow,
                transition: styles.transitions.default
            },

            '&_prev': {
                '&::before': {
                    content: '"\\e90c"'
                }
            },

            '&_next': {
                '&::before': {
                    content: '"\\e90d"'
                }
            },

            '&:hover': {
                '&::before': {
                    color: colors.white
                },
            }
        },

        '&__img-list': {
            width: '100%',
            height: '100%',

            '.slick-list, .slick-track, .slick-slide > div': {
                height: '100%'
            }
        },

        '&__img-slide': {
            width: '100%',
            height: '100%',
        },

        '&__img-container': {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
        },

        '&__img-wrap': {
            position: 'relative',
            width: '100%',

            '&._1-2': {
                width: 'calc(50% - 5px)'
            },
        },

        '&__img': {
            ...styles.of(),
            ...styles.absolute()
        }
    })
);
