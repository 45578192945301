import { css } from 'emotion';
import { styles, grid, grid_negative } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        paddingTop: grid.default[8],
        marginBottom: grid.default[8],

        '&__col': {
            '&_left': {
                paddingTop: [grid.default[5][0], grid.default[5][1], 0]
            },
            '&_right': {
                marginTop: [null, null, 0]
            }
        },

        '&__bg': {
            ...styles.absolute(0,0,'auto', 'auto'),
            width: 'calc(100% / 12 * 4)',
            height: [630, 455, 310],
            marginTop: grid_negative.default[4],
            zIndex: 0,
            overflow: 'hidden'
        },


        '&__bg-img': {
            ...styles.of(),
            ...styles.absolute()
        }
    })
);
