import React, { Component } from 'react';
import { default as st } from './style';
import TitleBlock from "../../components/TitleBlock";
import AsideTabs from "../../components/AsideTabs";
import PortfolioSlider from "../../components/PortfolioSlider";
import InView from "react-intersection-observer";

export default class Portfolio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        }

        this.counts = null;
    }

    getAllSlidersSlidesCounts = (array) => {
        this.counts = array.map((item) => {
            return item.list.length
        });

        return this.counts;
    };

    getPrevSlidesCount = (counts, index) => {
        let counter = 0;

        for (let i = 0; i < index; i++) {
            counter += counts[i]
        }

        return counter;
    };

    renderSlider = (categories, index) => {
        const
            counts = this.counts || this.getAllSlidersSlidesCounts(categories),
            prevSlidesCount = this.getPrevSlidesCount(counts, index);

        return (
            <PortfolioSlider key={index} list={categories[index].list} counterStart={prevSlidesCount} />
        )
    };

    switchTab = (index) => {
        this.setState({
            activeTab: index
        })
    };

    render() {
        const
            { title, categories } = this.props,
            { activeTab } = this.state;

        return (
            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div ref={ref} className={`${st}${inViewMod}`}>
                            <div className={`${st}__container container`}>
                                <div className={`${st}__inner-container inner-container`}>
                                    <div className={`${st}__table grid-table`}>
                                        <div className={`${st}__col ${st}__col_left col _3-10 animate animate-up`}>
                                            <div className={`${st}__aside-wrap`}>
                                                {title &&
                                                    <TitleBlock
                                                        title={title}
                                                    />
                                                }

                                                {categories &&
                                                    <AsideTabs list={categories} active={activeTab} clickHandler={this.switchTab} />
                                                }
                                            </div>
                                        </div>
                                        <div className={`${st}__col ${st}__col_right col _7-10 animate animate-up`}>
                                            {categories &&
                                                this.renderSlider(categories, activeTab)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>


        );
    }
}

