import React, { Component } from 'react';
import { default as st } from './style';

import {InView} from "react-intersection-observer";

import PhonesList from "../../components/PhonesList";
import Button from "../../components/Button";
import Nav from "../../components/Nav";
import WorkTime from "../../components/WorkTime";
import { animateScroll as scroll } from 'react-scroll';
import MediaQuery from "react-responsive";
import styles from "../../constants/styles";
import ScrollWrap from "../../components/ScrollWrap";
import Hamburger from "../../components/Hamburger";

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuIsOpen: false
        }

        this.hashScrollSettings = {
            hashSpy: true,
            smooth: true,
            duration: 1000,
            offset: 0
        }

        this.header = React.createRef();
        this.headerHeight = 0;
    }

    componentDidMount() {
        this.hashScrollSettings.offset = -this.header.current.offsetHeight;
    }

    toggleMenu = (e, bool) => {
        this.setState({
            menuIsOpen: typeof bool !== 'undefined' ? bool : !this.state.menuIsOpen
        })
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    renderNav = (nav) => {
        return (
            <div className={`${st}__col ${st}__col_nav-wrap`}>
                <Nav list={nav} scrollSettings={this.hashScrollSettings} callback={this.toggleMenu}/>
            </div>
        )
    }

    renderPhones = (phones_list) => {
        return (
            <div className={`${st}__col ${st}__col_phones-wrap`}>
                <PhonesList list={phones_list} />
            </div>
        )
    }

    renderTime = (work_time) => {
        return (
            <div className={`${st}__col ${st}__col_work-time-wrap`}>
                <WorkTime list={work_time} />
            </div>
        )
    }

    renderButton = () => {
        return (
            <div className={`${st}__col ${st}__col_button-wrap`}>
                <Button
                    bordered={true}
                    text="заказать звонок"
                    target="feedback"
                />
            </div>
        )
    }

    render() {
        const
            { nav, work_time, phones_list } = this.props,
            { menuIsOpen } = this.state,
            menuMod = menuIsOpen ? ' _active' : '';

        return (
            <div id={'header'} ref={this.header} className={`${st}`}>
                <MediaQuery maxWidth={`${styles.breakpoints[1800]}px`}>
                    <div className={`${st}__menu-wrap${menuMod}`}>
                        <div className={`${st}__close-button`} onClick={this.toggleMenu} />

                        <div className={`${st}__scroll-wrap`}>
                            <ScrollWrap>
                                <div className={`${st}__menu-container`}>
                                    {nav && this.renderNav(nav)}

                                    <MediaQuery maxWidth={`${styles.breakpoints[1000]}px`}>
                                        {phones_list && this.renderPhones(phones_list)}
                                        {work_time && this.renderTime(work_time)}
                                        {this.renderButton()}
                                    </MediaQuery>
                                </div>
                            </ScrollWrap>
                        </div>
                    </div>
                </MediaQuery>

                <InView threshold={1}>
                    {({ inView, ref }) => {
                        const stickyMod = inView ? '' : ' _sticky';

                        return (
                            <div ref={ref} className={`${st}__wrap`}>
                                <div id={'header-sticky-container'} className={`${st}__container ${stickyMod}`}>
                                    <div className={'container'}>
                                        <div className={`${st}__inner-container`}>
                                            <div className={`${st}__logo`} onClick={this.scrollToTop}/>

                                            <MediaQuery maxWidth={`${styles.breakpoints[1800]}px`}>
                                                <Hamburger callback={this.toggleMenu} />
                                            </MediaQuery>

                                            {nav &&
                                            <MediaQuery minWidth={`${styles.breakpoints[1800]+1}px`}>
                                                {this.renderNav(nav)}
                                            </MediaQuery>
                                            }

                                            {phones_list &&
                                            <MediaQuery minWidth={`${styles.breakpoints[1000]+1}px`}>
                                                {this.renderPhones(phones_list)}
                                            </MediaQuery>
                                            }

                                            {work_time &&
                                            <MediaQuery minWidth={`${styles.breakpoints[1300]+1}px`}>
                                                {this.renderTime(work_time)}
                                            </MediaQuery>
                                            }

                                            <MediaQuery minWidth={`${styles.breakpoints[1300]+1}px`}>
                                                {this.renderButton()}
                                            </MediaQuery>

                                            <MediaQuery minWidth={`${styles.breakpoints[1000]+1}px`} maxWidth={`${styles.breakpoints[1300]}px`}>
                                                <div className={`${st}__col-right`}>
                                                    {work_time && this.renderTime(work_time)}
                                                    {this.renderButton()}
                                                </div>
                                            </MediaQuery>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    }}
                </InView>
            </div>
        );
    }
}