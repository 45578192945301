import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        marginBottom: [grid.default[8][0], grid.default[8][1], grid.default[8][2], 40, 20],
        overflow: 'hidden',

        '&__col': {
            '&_left': {
                paddingTop: [grid.default[5][0], grid.default[5][1], 0],
            }
        },

        '&__bg-text-wrap': {
            width: '100%'
        },

        '&__bg-text': {
            position: 'relative',
            whiteSpace: 'nowrap',
            ...fonts.p1,
            color: colors.gray1,
            marginTop: [null, '-0.15em', '-0.3em'],

            span: {
                opacity: 0,
                transition: 'opacity .1s ease'
            },

            '&:not(._in-view)': {
                span: {
                    transitionDelay: '0s !important'
                },
            },

            '&._in-view': {
                span: {
                    opacity: 1
                },
            }
        }
    })
);
