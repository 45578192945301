import React, { Component } from 'react';
import { default as st } from './style';

export default class PhonesList extends Component {
    render() {
        const { list } = this.props;

        return (
            <div className={`${st}`}>
                <div className={`${st}__list`}>
                    {list.map(({text, url}, key) => (
                        <a key={key} href={url} className={`${st}__link`}>
                            {text}
                        </a>
                    ))}
                </div>
            </div>
        );
    }
}