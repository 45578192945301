export default function fetchQuery(obj) {
	const
		{ url, method, body, customSuccess } = obj,
		fetchObj = {
			method: method || 'GET'
		};
	
	if (body) fetchObj.body = body;
	
	return fetch(url, fetchObj)
	.then((response) => response.json())
	.then((responseJson) => {
		if (customSuccess) {
			customSuccess(responseJson);
		} else {
			if (this && this.state) {
				this.setState({
					...responseJson
				});
			}

		}
	})
	.catch((error) => {
		console.error(error);
	});
}
