import { css } from 'emotion';
import { styles } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        '&__substrate': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            zIndex: '2',
            pointerEvents: 'none'
        },

        '&:hover &, &._in-view &': {
            '&__line': {
                transform: 'translate(0, 0)',
                transitionDuration: '150ms',
                ...styles.setDelays({count: 4, delay: 150}),
            }
        },

        '&__line': {
            background: styles.colors.yellow,
            transitionProperty: 'transform',
            transitionTimingFunction: 'linear',
            transitionDuration: '100ms',
            ...styles.setDelays({count: 4, delay: 100, reverse: true}),

            '&_top, &_bottom': {
                width: '100%',
                height: [15, 10, 7, null, 5],
            },

            '&_left, &_right': {
                height: '100%',
                width: [15, 10, 7, null, 5],
            },

            '&_top': {
                ...styles.absolute(0,'auto','auto'),
                transform: 'translate(-101%, 0)'
            },

            '&_right': {
                ...styles.absolute(0,0,'auto','auto'),
                transform: 'translate(0, -101%)'
            },

            '&_bottom': {
                ...styles.absolute('auto',0,0,'auto'),
                transform: 'translate(101%, 0)'
            },

            '&_left': {
                ...styles.absolute('auto','auto'),
                transform: 'translate(0, 101%)'
            }
        }
    })
);
