import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingTop: grid.default[4],
        paddingBottom: grid.default[4],

       '&__container': {
            width: ['calc(100% / 12 * 4)', 'calc(100% / 12 * 5)', 'calc(100% / 12 * 6)', 'calc(100% / 12 * 8)', null, 'calc(100% - 60px)'],
           position: 'relative',
           margin: 'auto'
       },

       '&__text-wrap': {
           position: 'relative',
           paddingTop: grid.default[5],
           paddingBottom: grid.default[9],
           width: 'calc(100% / 4 * 3)',
           zIndex: 2
       },

       '&__border-wrap': {
            ...styles.absolute(0,0,'auto','auto'),
            width: 'calc(100% / 4 * 3)',
           height: '100%',
           zIndex: 1
       },

       '&__text': {
            ...fonts.p2,
           marginTop: grid.default[1],
           color: colors.dark
       },
    })
);
