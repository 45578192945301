import React, { Component } from 'react';
import { default as st } from './style';
import parse from "html-react-parser";
import Button from '../../components/Button';
import InputStyle from '../../components/InputStyle';
import FileUpload from "../../components/FileUpload";
import BorderSubstrate from "../../components/BorderSubstrate";
import ClosePopupButton from "../../components/ClosePopupButton";
import MediaQuery from "react-responsive";
import styles from "../../constants/styles";

export default class FormStructure extends Component {
    getColFields = (fields, side) => {
        let fieldsArray = [];

        for (let i = 0; i < fields.length; i++) {
            const { col } = fields[i];

            if (col === side) fieldsArray.push(fields[i]);
        }

        return fieldsArray;
    }

    renderFields = (fields) => {
        const { formikProps } = this.props

        return fields.map((item, key) => {
            const
                { type } = item,
                isHidden = type === 'hidden',
                isFileUpload = type === 'file',
                inputMod = isHidden ? ' pseudo-hidden' : '';

            return (
                isHidden
                    ?
                        <InputStyle key={key} {...item} mod={inputMod}/>
                    :
                        <div key={key} className={`${st}__row-item`}>
                            {isFileUpload
                                ?
                                    <FileUpload {...item} formikProps={formikProps}/>
                                :
                                    <InputStyle {...item}/>
                            }
                        </div>
            );
        })
    }

    renderContent = (fields, columns, button, buttonMod) => {
        return (
            columns
                ?
                    <div className={`${st}__table`}>
                        <div className={`${st}__col`}>
                            {this.renderFields(this.getColFields(fields, 'left'))}
                        </div>
                        <div className={`${st}__col`}>
                            {this.renderFields(this.getColFields(fields, 'right'))}
                            {this.renderButton(button.text, buttonMod)}
                        </div>
                    </div>
                :
                    <React.Fragment>
                        {this.renderFields(fields)}
                        {this.renderButton(button.text, buttonMod)}
                    </React.Fragment>
        )
    }

    renderButton = (text, mod) => {
        return (
            <div className={`${st}__btn-wrap${mod}`}>
                <Button
                    text={text}
                    tag={'button'}
                    shadow={true}
                />
            </div>
        )
    }

    render() {
        const { columns=false, button, bottom, fields, inViewMod='', formMod='', buttonMod='', closeBtn=false } = this.props;

        return (
            <div className={`${st}${formMod}`}>
                {closeBtn &&
                    <MediaQuery minWidth={`${styles.breakpoints[500]+1}px`}>
                        <ClosePopupButton />
                    </MediaQuery>
                }

                <BorderSubstrate
                    className={`${st}__wrap${inViewMod}`}
                >
                    <div className={`${st}__container`}>
                        {this.renderContent(fields, columns, button, buttonMod)}
                    </div>
                </BorderSubstrate>

                <div className={`${st}__bottom`}>
                    {parse(bottom)}
                </div>
            </div>
        );
    }
}
