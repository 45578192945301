import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        width: '100%',
        maxWidth: [188],
        height: [60, 56, null, null, 45],
        background: 'rgba(255, 255, 255, .1)',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'flex-start',

        '&__arrow': {
            width: [50],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',

            '&::before': {
                fontSize: 14,
                lineHeight: '1',
                fontWeight: '100',
                color: colors.white,
                transition: styles.transitions.default,
            },

            '&:hover': {
                '&::before': {
                    color: colors.yellow,
                },
            }
        },

        '&__input-wrap': {
            position: 'relative',
            flex: '1 0 0',
        },

        '&__input': {
            width: '100%',
            height: '100%',
            background: 'transparent',
            ...fonts.b1,
            color: colors.white,
            textAlign: 'center',
            paddingRight: '1.5em',
            boxSizing: 'border-box'
        },

        '&__suffix': {
            ...styles.absolute(0,0,0,'auto'),
            ...fonts.b1,
            color: colors.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            pointerEvents: 'none'
        },


        '&:last-child': {
            marginBottom: 0
        },

        '&:hover': {

        }
    })
);
