import React, {Component} from 'react';
import { default as st } from './style';

export default class TitleBlock extends Component {
    renderIconLink = (label) => {
        const { icon, text, url } = label;

        return (
            <a className={`${st}__icon-link ${icon}`} href={url} target={'_blank'} rel="noopener noreferrer" >{text}</a>
        )
    };

    render() {
        const
            { title, subtitle, iconLink, mod='', delayAnimation=false } = this.props,
            animationMod = delayAnimation ? 'animate-title-d' : 'animate-title',
            iconMod = iconLink ? ' _icon' : '';

        return (
            <div className={`${st}${mod} ${animationMod}`}>
                <div className={`${st}__container`}>
                    {title &&
                        <h2 className={`${st}__title${iconMod}`}>
                            <span>{title}</span>

                            {iconLink && (
                                this.renderIconLink(iconLink)
                            )}
                        </h2>
                    }

                    {subtitle &&
                        <h3 className={`${st}__subtitle`}>
                            <span>{subtitle}</span>
                        </h3>
                    }
                </div>
            </div>
        )
    }
}
