import React, { Component } from 'react';
import { default as st } from './style';
import ReactDOM from "react-dom";

export default class GalleryLink extends Component {
    constructor(props) {
        super(props);
        this.mouse_handler = this.mouse_handler.bind(this);
        this.link = React.createRef();
        this.state = {
            left: 0,
            top: 0
        };
    }

    mouse_handler = e => {
        let el_event = e.nativeEvent,
            btn_coords = ReactDOM.findDOMNode(this.link.current).getBoundingClientRect(),
            rel_x = el_event.x - btn_coords.x,
            rel_y = el_event.y - btn_coords.y;

        this.setState({
            left: rel_x,
            top: rel_y
        });
    };

    render() {
        const
            { img, href, inView } = this.props,
            { top, left } = this.state,
            circle_styles = {
                left: left,
                top: top
            };

        return (
            <a
                className={`${st} animate-img`}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={this.mouse_handler}
                onMouseLeave={this.mouse_handler}
                ref={this.link}
            >
                <div className={`${st}__circle`} style={circle_styles} />
                <div className={`${st}__img-wrap`}>
                    {inView && <img src={img} alt={img} className={`${st}__img`} />}
                </div>
            </a>
        );
    }
}
