import { css } from 'emotion';
import { styles } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        '&__list': {
            display: 'flex',
            flexDirection: [null, 'column'],
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
    })
);
