import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        width: '100%',
        maxWidth: [660],

        '&__title': {
            ...fonts.b1,
            color: colors.white,
            opacity: .2,
            marginBottom: grid.default[0]
        },

        '&__row': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginBottom: grid.default[4],

            '&:last-child': {
                marginBottom: 0
            }

        },

        '&__fields-set': {
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            flexWrap: 'wrap',

            '&._separate': {
                width: 'calc(100% + 20px)',
                left: -10,
                marginTop: [-20],
            },

            '&._top': {
                alignItems: 'flex-start',
            },
        },

        '&__col': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            margin: ['20px 10px 0'],

            '&._1-3': {
                width: ['calc(100% / 3 - 20px)', null, null, 'calc(50% - 20px)', null, 'calc(100% - 20px)']
            },
            '&._1-4': {
                width: ['calc(100% / 4 - 20px)', null, null, 'calc(100% / 3 - 20px)', null, 'calc(50% - 20px)']
            },
            '&._1-5': {
                width: ['calc(100% / 5 - 20px)', null, null, 'calc(100% / 4 - 20px)', 'calc(100% / 3 - 20px)', 'calc(50% - 20px)']
            },
        }

    })
);
