import { css } from 'emotion';
import { styles, grid } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        marginBottom: grid.default[8],
        background: [null, styles.colors.gray1],

        '&__img-wrap': {
            width: '100%',
            maxWidth: [350, 260, 'none'],
            height: [460, 400, 470, 373, 197],

            picture: {
                display: 'block',
                position: 'relative',
                width: '100%',
                height: '100%',

                img: {
                    ...styles.of(),
                }
            }
        },

        '&__title-wrap': {
            width: '100%',
            maxWidth: 400,
        },

        '&__img': {
            ...styles.of(),
        },

        '&__table': {
            paddingTop: [null, grid.default[8][0], grid.default[8][2], grid.default[8][3], grid.default[8][4]],
        },

        '&__col': {
            paddingTop: [grid.default[8][0], 0],

            '&_left': {
                zIndex: 2
            },

            '&_right': {
                paddingBottom: grid.default[8],
                marginTop: [null, null, 60, null, 50],
                zIndex: 1,

                ':before': {
                    content: '""',
                    display: ['block', 'none'],
                    ...styles.absolute(0,'auto','auto', ['calc(-100% / 7 * 4)', ]),
                    width: '100vw',
                    height: '100%',
                    background: styles.colors.gray1,
                }
            }
        }
    })
);
