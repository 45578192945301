import React, { Component } from 'react';
import { default as st } from './style';
import { connect } from "react-redux";
import { setPopupData } from "../../actions/setPopupData";

class TextButton extends Component {
    clickHandler = (target) => {
        if (target) {
            const { setPopupData, additionalFormData } = this.props;

            setPopupData({
                show: true,
                type: target,
                additionalFormData: additionalFormData
            });
        }
    };

    render() {
        const
            { text, target } = this.props;

        return (
            <div
                className={`${st}`}
                onClick={() => {
                    this.clickHandler(target)
                }}
            >
                {text}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPopupData: obj => dispatch(setPopupData(obj))
    }
};

export default connect(null, mapDispatchToProps)(TextButton);

