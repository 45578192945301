import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        '&__label': {
            paddingLeft: [10],
            ...fonts.p4,
            color: colors.dark,
            maxWidth: 150
        },

        svg: {
            display: 'block',
            width: [30],
            height: [47],

            path: {
                transition: styles.transitions.default,

                '&:nth-child(1)': {
                    fill: colors.dark
                },

                '&:nth-child(2)': {
                    fill: colors.yellow
                }
            }
        },

        '&:hover': {
            svg: {
                path: {
                    '&:nth-child(1)': {
                        fill: colors.yellow
                    },

                    '&:nth-child(2)': {
                        fill: colors.dark
                    }
                }
            },
        }
    })
);
