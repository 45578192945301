import React, { Component } from 'react';
import { default as st } from './style';
import ReactDOM from "react-dom";
import { Link } from 'react-scroll';

export default class NavItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            left: 0,
            top: 0
        };

        this.btn = React.createRef();
    }

    mouse_handler = e => {
        let el_event = e.nativeEvent,
            btn_coords = ReactDOM.findDOMNode(this.btn.current).getBoundingClientRect(),
            rel_x = el_event.x - btn_coords.x,
            rel_y = el_event.y - btn_coords.y;

        this.setState({
            left: rel_x,
            top: rel_y
        });
    };

    render() {
        const
            { hash, text, scrollSettings, callback } = this.props,
            { top, left } = this.state,
            circle_styles = {
                left: left,
                top: top
            };

        return (
            <Link
                ref={this.btn}
                to={hash}
                className={`${st}`}
                onMouseEnter={this.mouse_handler}
                onMouseLeave={this.mouse_handler}
                onClick={(e) => { callback(e, false) }}
                {...scrollSettings}
            >
                <div className={`${st}__wrap`}>
                    <div className={`${st}__circle`} style={circle_styles} />
                    <span className={`${st}__text`}>{text}</span>
                </div>
            </Link>
        );
    }
}