import React, { Component } from 'react';
import { default as st } from './style';
import styles from "../../constants/styles";
import MediaQuery from 'react-responsive';
import formatCurrency from "../../utils/format-currency";
import BorderSubstrate from "../BorderSubstrate";
import Button from "../Button";
import TextButton from "../TextButton";
import {connect} from "react-redux";

class CalcTotal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineStyles: {
                transform: `translate3d(0, 0, 0)`
            }
        }

        this.totalContainer = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("scroll", this.setPosition);
        this.header = document.getElementById('header-sticky-container');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.calcTotal !== this.props.calcTotal) this.setPosition();
    }

    setPosition = () => {
        const
            { totalWrap, table } = this.props,
            wrap = totalWrap.current || table.current,
            totalContainer = this.totalContainer.current;


        if (wrap && totalContainer) {
            const
                headerHeight = this.header.offsetHeight + 20,
                wrapPosition = wrap.getBoundingClientRect(),
                wrapTopUpperWindowTop = (wrapPosition.top - headerHeight) <= 0,
                wrapBottomUnderWindowTop = (wrapPosition.bottom - headerHeight) >=0,
                setContainerPosition = wrapTopUpperWindowTop && wrapBottomUnderWindowTop;

            if (setContainerPosition) {
                const
                    containerPosition = totalContainer.getBoundingClientRect(),
                    offset = -(wrapPosition.top - headerHeight),
                    maxOffset = wrapPosition.height - containerPosition.height,
                    setOffset = offset <= maxOffset ? offset : maxOffset;

                this.setOffsetTop(setOffset);
            }
        }
    };

    setOffsetTop = (val) => {
        const styles = {
            transform: `translate3d(0, ${val}px, 0)`
        }

        this.setState({
            inlineStyles: styles
        })
    }

    render() {
        const
            { label, button, links, calcTotal, inViewMod } = this.props,
            { total=0 } = calcTotal,
            { inlineStyles } = this.state;

        return (
            <div ref={this.totalContainer} className={`${st}`} style={inlineStyles}>
                <MediaQuery minWidth={`${styles.breakpoints[1300]+1}px`}>
                    <div className={`${st}__label rotated-text animate-d animate-up`}>
                        <span>{label}</span>
                    </div>
                </MediaQuery>


                <BorderSubstrate className={`${st}__container${inViewMod}`}>
                    <div className={`${st}__price`}>
                        {formatCurrency(total)}
                    </div>

                    <div className={`${st}__btn-wrap`}>
                        <Button
                            text={button}
                            target="calc"
                            shadow={true}
                            additionalFormData={calcTotal}
                        />
                    </div>

                    <MediaQuery minWidth={`${styles.breakpoints[1000]+1}px`}>
                        <div className={`${st}__links-wrap`}>
                            {links.map((item, key) => <TextButton key={key} text={item} target={'calc'} additionalFormData={{button: item}} />)}
                        </div>
                    </MediaQuery>
                </BorderSubstrate>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        calcTotal: store.calcTotal
    }
};

export default connect(mapStateToProps)(CalcTotal);

