import React, { Component } from 'react';
import { default as st } from './style';
import parse from "html-react-parser";
import InView from "react-intersection-observer";
import TitleBlock from "../../components/TitleBlock";
import PhonesList from "../../components/PhonesList";
import TutmeeLogo from "../../components/TutmeeLogo";
import styles from "../../constants/styles";
import MediaQuery from "react-responsive";

export default class Contacts extends Component {
    renderMap = (map_link) => {
        const
            url = new URL(map_link),
            query_string = url.search,
            search_params = new URLSearchParams(query_string),
            id = search_params.get('um'),
            src = `https://yandex.ru/map-widget/v1/?um=${id}&source=constructor`

        return (
            <InView triggerOnce={true} rootMargin={'500px 0px'}>
                {({ inView, ref }) => (
                    <div ref={ref} className={`${st}__map-container`}>
                        {inView && <iframe title={'map'} src={src} />}
                    </div>
                )}
            </InView>
        )
    }

    render() {
        const { title, content, email, copyright, map_link, phones_list } = this.props;

        return (

            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div ref={ref} className={`${st}${inViewMod}`}>
                            <div className={`${st}__container container`}>
                                <div className={`${st}__inner-container inner-container _right`}>
                                    <div className={`${st}__table grid-table`}>
                                        <div className={`${st}__col ${st}__col_left col _3-11`}>
                                            <div className={'left-col-container'}>
                                                <TitleBlock title={title} />

                                                {map_link &&
                                                    <MediaQuery maxWidth={`${styles.breakpoints[1300]+1}px`}>
                                                        {this.renderMap(map_link)}
                                                    </MediaQuery>
                                                }

                                                <div className={`${st}__contacts-list animate animate-up`}>
                                                    <div className={`${st}__row`}>
                                                        {parse(content)}
                                                    </div>
                                                    <div className={`${st}__row`}>
                                                        <PhonesList list={phones_list} />
                                                    </div>
                                                    <div className={`${st}__row`}>
                                                        <a className={`${st}__email`} href={`mailto:${email}`}>{email}</a>
                                                    </div>
                                                </div>

                                                <div className={`${st}__tutmee-logo`}>
                                                    <TutmeeLogo />
                                                </div>
                                            </div>
                                        </div>

                                        <MediaQuery minWidth={`${styles.breakpoints[1300]+1}px`}>
                                            <div className={`${st}__col ${st}__col_right col _8-11`}>
                                                {map_link &&
                                                    this.renderMap(map_link)
                                                }
                                            </div>
                                        </MediaQuery>
                                    </div>
                                    <div className={`${st}__bottom grid-table`}>
                                        <div className={`${st}__copyright-wrap col _3-11`}>
                                            <span className={`${st}__copyright`}>
                                                {copyright}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>



        );
    }
}

