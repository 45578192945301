import { css } from 'emotion';
import { styles } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        height: [50, null, null, null, 40],
        width: [50, null, null, null, 40],
        marginLeft: [50, null, 'auto', 'auto'],
        marginRight: ['auto', null, 0, 0],
        background: colors.dark,
        cursor: 'pointer',
        transition: styles.transitions.default,

        '&:hover': {
            background: colors.yellow,
        },

        '&:hover &__lines': {
            background: colors.dark,
        },

        '&__lines': {
            ...styles.absolute(),
            width: '50%',
            height: [3, null, null, null, 2],
            background: colors.white,
            transition: styles.transitions.default,

            '&::before, &::after': {
                content: '""',
                display: 'block',
                height: '100%',
                width: '100%',
                background: 'inherit',
            },

            '&::before': {
                ...styles.absolute('auto', 0, [11, null, null, null, 8])
            },

            '&::after': {
                ...styles.absolute([11, null, null, null, 8], 0, 'auto')
            }
        }
    })
);
