import React, { Component } from 'react';
import { default as st } from './style';
import InView from "react-intersection-observer";
import GalleryLink from "../GalleryLink";

export default class LinksGalleryList extends Component {
    render() {
        const { list } = this.props;

        return (
            <div className={`${st}`}>
                <InView triggerOnce={true} rootMargin={'500px 0px'}>
                    {({ inView, ref }) => (
                        <div ref={ref} className={`${st}__list`}>
                            {list.map((item, key) => (
                                <GalleryLink key={key} inView={inView} {...item} />
                            ))}
                        </div>
                    )}
                </InView>

            </div>
        );
    }
}
