import React, { Component } from 'react';
import { default as st } from './style';
import { connect } from "react-redux";
import { setPopupData } from "../../actions/setPopupData";

class ClosePopupButton extends Component {
    clickHandler = () => {
        const { setPopupData } = this.props;

        setPopupData({
            show: false
        });
    };

    render() {
        const { className='' } = this.props;

        return (
            <div className={`${st} ${className}`} onClick={() => {this.clickHandler()}} />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPopupData: obj => dispatch(setPopupData(obj))
    }
};

export default connect(null, mapDispatchToProps)(ClosePopupButton);

