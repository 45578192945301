import styles from "../constants/styles";

let fonts = {
    h1: {
        fontFamily: styles.fontFamily.bebasneue,
        fontSize: [70, 54, 48, 44, 32],
        lineHeight: ['87px', '67px', '60px', '44px', '32px'],
        letterSpacing: ['0.16em'],
        textTransform: 'uppercase',
        fontWeight: 700,
        color: styles.colors.dark
    },
    h2: {
        fontFamily: styles.fontFamily.bebasneue,
        fontSize: [40, 36, 32, null, 24],
        lineHeight: ['44px', '40px', '35px', null, '27px'],
        letterSpacing: ['0.12em'],
        textTransform: 'uppercase',
        fontWeight: 700,
        color: styles.colors.dark
    },
    h3: {
        fontFamily: styles.fontFamily.bebasneue,
        fontSize: [28, 24, null, null, 20],
        lineHeight: ['31px', '27px', null, null, '22px'],
        letterSpacing: ['0.12em'],
        textTransform: 'uppercase',
        fontWeight: 700,
        color: styles.colors.dark
    },

    p1: {
        fontFamily: styles.fontFamily.bebasneue,
        fontSize: [288, 240, 220, 180, 140],
        lineHeight: '1',
        textTransform: 'uppercase',
        fontWeight: 700
    },
    p2: {
        fontFamily: styles.fontFamily.bebasneue,
        fontSize: [24, 23, 21, 20, 19],
        lineHeight: ['27px', '25px', '23px', '22px', '21px'],
        letterSpacing: ['0.12em'],
        textTransform: 'uppercase',
        fontWeight: 400
    },
    p3: {
        fontFamily: styles.fontFamily.roboto,
        fontSize: [16, 15, 14],
        lineHeight: ['22px', '21px', '20px'],
        fontWeight: 400
    },
    p4: {
        fontFamily: styles.fontFamily.roboto,
        fontSize: [14],
        lineHeight: ['20px'],
        fontWeight: 400
    },

    b1: {
        fontFamily: styles.fontFamily.bebasneue,
        fontSize: [20, 18],
        lineHeight: '1',
        letterSpacing: ['0.12em'],
        textTransform: 'uppercase',
        fontWeight: 700
    },

    menu: {
        fontFamily: styles.fontFamily.bebasneue,
        fontSize: 20,
        lineHeight: '1',
        letterSpacing: ['0.12em'],
        fontWeight: 400
    }
};

export default fonts;
