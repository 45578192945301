import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        width: '100%',

        '&__input-wrap': {
            position: 'relative',
        },

        '&__input': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2,
        },

        '&__input:hover ~ &__img-wrap, &__input:checked ~ &__img-wrap': {
            '&::before': {
                opacity: 1,
            }
        },

        '&__img-wrap': {
            position: 'relative',
            width: '100%',
            ...styles.rh(),
            zIndex: 1,

            '&::before': {
                content: '""',
                ...styles.absolute(),
                width: 'calc(100% - 16px)',
                height: 'calc(100% - 16px)',
                border: `10px solid ${colors.yellow}`,
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                transition: styles.transitions.default,
                opacity: 0,
                zIndex: 3
            }
        },

        '&__img': {
            ...styles.of(),
            ...styles.absolute()
        },

        '&__label': {
            display: 'block',
            ...fonts.p4,
            marginTop: grid.default[0]

        },

        '&._icon &__img-wrap': {
            '&::before': {
                content: '"\\e902"',
                fontFamily: styles.fontFamily.icomoon,
                fontSize: [26],
                color: colors.dark,
                lineHeight: '1',
                fontWeight: '100',
            }
        }
    })
);
