import { css, keyframes } from 'emotion';
import { styles, grid } from '../../constants';
const { mq, colors } = styles;

const showHeader = keyframes`
    0% {
        opacity: 0;
    }
    
    100% {
        opacity: 1;
    }
`;

export default css(
    mq({
        ...styles.absolute('0', '0', 'auto'),
        width: '100%',
        zIndex: 90,
        paddingTop: grid.default[2],
        opacity: 0,
        animation: `${showHeader} 1s ease both`,
        animationDelay: '1s',

        '&__menu-wrap': {
            ...styles.fixed(0,0,'auto'),
            width: '100%',
            height: '100vh',
            background: colors.white,
            transitionTimingFunction: 'ease',
            transitionProperty: 'top, opacity',
            transitionDuration: '0s, 1s',
            transitionDelay: '1s, 0s',
            top: '-110vh',
            opacity: 0,
            zIndex: '990',

            '&._active': {
                top: 0,
                opacity: 1,
                transitionDelay: '0s, 0s',
            }
        },

        '&__close-button': {
            ...styles.absolute(0, 10, 'auto', 'auto'),
            padding: 20,
            cursor: 'pointer',
            zIndex: 20,

            '&:hover': {
                '&::before': {
                    color: colors.yellow,
                },
            },

            '&::before': {
                content: '"\\e90b"',
                display: 'block',
                fontFamily: styles.fontFamily.icomoon,
                fontSize: 16,
                lineHeight: '1',
                fontWeight: '100',
                color: colors.dark,
                transition: styles.transitions.default
            },
        },

        '&__menu-container': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto',
            width: '100%',
            height: 'auto',
            padding: '50px 0'
        },

        '&__scroll-wrap': {
            position: 'relative',
            height: '100%',
            width: '100%',
            zIndex: 10
        },

        '&__wrap': {
            width: '100%',
            height: styles.headerHeight
        },

        '&__container': {
            transition: 'background .3s ease',

            '&._sticky': {
                ...styles.fixed('0','0','auto'),
                background: styles.colors.white,
                paddingTop: [15, null, null, 5],
                paddingBottom: [15, null, null, 5]
            }
        },

        '&__container._sticky &': {
            '&__logo': {
                ':before': {
                    opacity: 0
                },

                ':after': {
                    opacity: 1
                },
            },

            '&__inner-container': {
                height: [75, null, 90, 50, 40]
            },

        },

        '&__inner-container': {
            height: styles.headerHeight,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: ['calc(100% / 12 * 10)', null, null, '100%'],
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: [null, null, null, 45, 30],
            paddingRight: [null, null, null, 45, 30],
        },

        '&__logo': {
            display: 'block',
            position: 'relative',
            height: '100%',
            width: [200, null, 170, 165, 115],
            cursor: 'pointer',

            ':before, &:after': {
                content: '""',
                ...styles.absolute(),
                width: '100%',
                height: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center center'
            },

            ':before': {
                backgroundImage: `url(${require('../../images/svg/logo_full.svg')})`
            },

            ':after': {
                backgroundImage: `url(${require('../../images/svg/logo_min.svg')})`,
                opacity: 0
            },
        },

        '&__col': {
            paddingLeft: 40,

            '&_nav-wrap': {
                marginRight: ['auto', 0],
                marginLeft: [null, 0],
                paddingLeft: [null, 0],
            },
            '&_phones-wrap': {
                paddingLeft: [null, null, null, 0],
                marginTop: [null, null, null, 10],
            },
            '&_work-time-wrap': {
                marginRight: [null, 'auto', null, 0],
                paddingLeft: [null, null, null, 0],
                marginTop: [null, null, null, 20],
            },
            '&_button-wrap': {
                paddingLeft: [null, null, null, 0],
                marginTop: [null, null, null, 20],
            }
        }
    })
);
