import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        '&__list': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },

        '&__link': {
            ...fonts.p4,
            textAlign: [null, null, null, 'center']
        }
    })
);
