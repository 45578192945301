import React, { Component } from 'react';
import { default as st } from './style';

export default class Counter extends Component {
    constructor(props) {
        super(props);

        this.input = React.createRef();
        this.timer = null;
    }

    keyDownHandler = (e) => {
        const keyCode = e.keyCode;

        if (
            keyCode === 46 ||
            keyCode === 8 ||
            keyCode === 9 ||
            keyCode === 27 ||
            (keyCode === 65 && e.ctrlKey === true) ||
            (keyCode >= 35 && keyCode <= 40)
        ) {
            return;
        } else {
            if (
                (keyCode < 48 || keyCode > 57) &&
                (keyCode < 96 || keyCode > 105)
            ) {
                e.preventDefault();
            }
        }
    }

    onChange = () => {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.setInputVal()
        }, 1000)

    }

    setInputVal = () => {
        const { title, name, callback, min_val, max_val, calc_type, range_parameters } = this.props;

        const
            input = this.input.current,
            currentVal = parseInt(input.value),
            lessMin = currentVal < min_val,
            moreMax = currentVal > max_val,
            isInteger = Number.isInteger(currentVal);

        if (isInteger) {
            if (lessMin) {
                input.value = min_val;
            } else if (moreMax) {
                input.value = max_val;
            } else {
                input.value = currentVal;
            }

        } else {
            input.value = min_val;
        }

        if (callback) callback({
            title: title,
            text: currentVal,
            name: name,
            val: currentVal,
            type: calc_type,
            parameters: range_parameters
        })
    }

    arrowHandler = (num) => {
        this.input.current.value = parseInt(this.input.current.value) + num;
        this.setInputVal()
    }

    render() {
        const { name, suffix, value, max_val } = this.props;

        return (
            <div className={`${st}`} >
                <div className={`${st}__arrow icon-arr-left`} onClick={() => {this.arrowHandler(-1)}}>

                </div>

                <div className={`${st}__input-wrap`} >
                    <input
                        ref={this.input}
                        type='text'
                        maxLength={max_val.toString().length}
                        name={name}
                        defaultValue={value}
                        className={`${st}__input`}
                        onChange={this.onChange}
                        onKeyDown={this.keyDownHandler}
                    />

                    <div className={`${st}__suffix`}>{suffix}</div>
                </div>

                <div className={`${st}__arrow icon-arr-right`} onClick={() => {this.arrowHandler(1)}}>

                </div>
            </div>
        );
    }
}

