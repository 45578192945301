import { css } from 'emotion';
import { styles } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        ...styles.absolute([50, 40, null, null, 20], [50, 40, null, null, 30], 'auto', 'auto'),
        cursor: 'pointer',
        zIndex: 9,

        '&::before': {
            content: '"\\e90b"',
            fontFamily: styles.fontFamily.icomoon,
            fontSize: 17,
            color: colors.dark,
            display: 'block',
            transition: styles.transitions.default
        },

        '&:hover': {
            '&::before': {
                color: colors.yellow
            }
        }
    })
);
