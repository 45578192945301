const actions = {
    popup: {
        SET_POPUP_DATA: 'SET_POPUP_DATA'
    },

    calc: {
        RESET_CALC_DATA: 'RESET_CALC_DATA',
        SET_CALC_DATA: 'SET_CALC_DATA',
        REMOVE_CALC_DATA: 'REMOVE_CALC_DATA',
    },

    calcTotal: {
        SET_CALC_TOTAL: 'SET_CALC_TOTAL',
    }
};

export default actions;
