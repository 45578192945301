import React, { Component } from "react";
import { default as st } from "./style";
import parse from "html-react-parser";

export default class TextBlock extends Component {
	render() {
		const { content, mod='' } = this.props;

		return (
			<div className={`${st}${mod}`}>
				{parse(content)}
			</div>
		);
	}
}