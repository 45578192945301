import React, { Component } from 'react';
import { default as st } from './style';

export default class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.input = React.createRef();
    }

    resetInput = () => {
        const
            { name, formikProps } = this.props,
            { setFieldValue } = formikProps;

        setFieldValue(name, '');
        if (this.input.current) this.input.current.value = null;
    }

    render() {
        const
            { name, placeholder, label, formikProps } = this.props,
            { values, setFieldValue } = formikProps;

        return (
            <div className={`${st}`}>
                {(values && values[name] && values[name].name)
                    ?
                        <div className={`${st}__file-preview`}>
                            <div className={`${st}__file-name-wrap`}>
                                <span className={`${st}__file-name`}>{values[name].name}</span>
                            </div>
                            <div className={`${st}__remove-button`} onClick={this.resetInput} />
                        </div>
                    :
                        <div className={`${st}__input-wrap`}>
                            <input
                                ref={this.input}
                                onChange={(event) => {
                                    setFieldValue(name, event.currentTarget.files[0]);
                                }}
                                type={'file'}
                                className={`${st}__input`}
                                placeholder={placeholder}
                            />

                            {label && (
                                <span className={`${st}__label`}>{label}</span>
                            )}

                            {placeholder && (
                                <span className={`${st}__placeholder`}>{placeholder}</span>
                            )}
                        </div>
                }
            </div>
        );
    }
}
