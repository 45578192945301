import { css } from 'emotion';
import {styles, fonts, grid, grid_negative} from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        display: 'flex',
        flexDirection: ['column', null, 'row', null, 'column'],
        flexWrap: 'wrap',
        alignItems: ['flex-start', null, 'stretch'],
        justifyContent: 'flex-start',
        padding: [10],
        background: colors.dark,

        '&__tab': {
            cursor: 'pointer',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: ['100%', null, '50%', '100%'],
            overflow: 'hidden',
            padding: ['12px 20px 8px'],
            transition: styles.transitions.default,

            '&._active': {
                background: colors.yellow
            }
        },

        '&__text': {
            ...fonts.b1,
            color: colors.white,
            boxSizing: 'border-box',
            width: '100%',
            transition: styles.transitions.default,
        },

        '&__tab:hover &': {
            '&__text': {
                color: colors.yellow
            }
        },

        '&__tab._active &': {
            '&__text': {
                color: colors.dark,
            }
        }
    })
);
