import { actions } from "../constants";

const initialState = {

};

export function calcTotal(state = initialState, action) {
    switch (action.type) {
        case actions.calcTotal.SET_CALC_TOTAL: {
            return {...state, ...action.payload}
        }

        default: {
            return state
        }
    }
}
