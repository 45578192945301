import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        width: '100%',

        '&__counter-wrap': {
            paddingTop: [100, 70, 60, 30, 0],
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: ['auto', null, null, null, 0]

        },
        '&__counter': {
            userSelect: 'none',
            display: [null, null, null, null, 'flex'],
            alignItems: [null, null, null, null, 'flex-start'],
            justifyContent: [null, null, null, null, 'space-between'],
            width: [null, null, null, null, '100%']
        },

        '&__counts': {
            position: 'relative',
        },

        '&__current': {
            ...styles.absolute('auto', 'calc(100% + 20px)', 0, 'ato'),
            ...fonts.p1,
            color: colors.gray1,
            lineHeight: '.72'
        },

        '&__total': {
            ...fonts.p2,
            marginTop: 10
        },

        '&__arrows-wrap': {
            display: [null, null, null, null, 'flex'],
            alignItems: [null, null, null, null, 'flex-start'],
            justifyContent: [null, null, null, null, 'space-between'],
            width: [null, null, null, null, '50%']
        },

        '&__arrow': {
            width: [40, null, null, null, 'calc(50% - 5px)'],
            height: [40],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            background: colors.dark,

            '&::before': {
                display: 'block',
                fontFamily: styles.fontFamily.icomoon,
                fontSize: [13],
                lineHeight: '1',
                color: colors.yellow,
                transition: styles.transitions.default
            },

            '&_prev': {
                '&::before': {
                    content: '"\\e90c"'
                }
            },

            '&_next': {
                '&::before': {
                    content: '"\\e90d"'
                }
            },

            '&:hover': {
                '&::before': {
                    color: colors.white
                },
            }
        },

        '&__table': {
            position: "relative",
            width: ["calc(100% / 12 * 10)", null, null, null, '100%'],
            paddingLeft: [null, null, null, null, 30],
            paddingRight: [null, null, null, null, 30],
            margin: "0 auto",
            display: 'flex',
            flexDirection: [null, null, null, null, 'column'],
            alignItems: ['stretch', null, null, null, 'flex-start'],
            justifyContent: ['space-between', null, null, null, 'flex-start'],
            overflow: 'hidden',
            minHeight: '100vh',
            paddingTop: grid.default[7],
            paddingBottom: grid.default[7],
        },

        '&__col': {
            '&_left': {
                width: ['calc(80% - 10px)', 'calc(70% - 10px)', null, null, '100%'],
                height: [null, null, null, null, 350]
            },
            '&_right': {
                paddingTop: [grid.default[5][0], grid.default[5][1], grid.default[5][2], grid.default[5][4], 30],
                width: ['calc(20% - 10px)', 'calc(30% - 10px)', null, null, '100%'],
                paddingLeft: [70, 30, 15, null, 0],
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
            },
        },

        '&__text-list': {
            width: '100%',
        },

        '&__img-sliders': {
            width: '100%',
            height: '100%',

            '.slick-list, .slick-track, .slick-slide > div': {
                height: '100%'
            }
        },

        '&__text-slide': {
            width: '100%',
            display: 'block',
            textAlign: 'left'
        },

        '&__img-slide': {
            width: '100%',
            height: '100%',
        },

        '&__img-container': {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
        },

        '&__img-wrap': {
            position: 'relative',
            width: '100%',

            '&._1-2': {
                width: 'calc(50% - 5px)'
            },
        },

        '&__img': {
            ...styles.of(),
            ...styles.absolute()
        },

        '&__title': {
            ...fonts.h3,
            marginBottom: grid.default[1],
        },

        '&__subtitle': {
            ...fonts.p2,
            marginBottom: grid.default[1],
        },

        '&__text-wrap': {
            width: '100%',
        }
    })
);
