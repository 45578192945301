import React, { Component } from "react";
import { default as st } from "./style";

export default class BorderSubstrate extends Component {
    render() {
        const
            { children, className='', active, ...rest } = this.props,
            activeClass = active ? '_active' : '';

        return (
            <div className={`${st} ${className} ${activeClass}`} {...rest}>
                <div className={`${st}__substrate`}>
                    <div className={`${st}__line ${st}__line_top`} />
                    <div className={`${st}__line ${st}__line_right`} />
                    <div className={`${st}__line ${st}__line_bottom`} />
                    <div className={`${st}__line ${st}__line_left`} />
                </div>

                {children}
            </div>
        )
    }
}
