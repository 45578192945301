import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',

        '&:hover': {
            '&::before': {
                color: colors.dark,
                background: colors.yellow,
            },
        },

        '&::before': {
            content: '"\\e903"',
            fontFamily: styles.fontFamily.icomoon,
            fontSize: [12],
            lineHeight: '1',
            fontWeight: '100',
            color: colors.yellow,
            background: 'rgba(255,255,255,.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: [36],
            height: [36],
            transition: styles.transitions.default,
            marginRight: [20]
        },

        '&__text': {
            ...fonts.b1,
            color: colors.yellow
        },

    })
);
