import React, { Component } from 'react';
import { default as st } from './style';

export default class AsideTabs extends Component {
    render() {
        const { list, active, clickHandler } = this.props;

        return (
            <div className={`${st}`}>
                {list.map(({title}, key) => {
                    const activeMod = active === key ? ' _active' : ''

                    return (
                        <div key={key} className={`${st}__tab${activeMod}`} onClick={() => {clickHandler(key)}}>
                            <div className={`${st}__text`}>
                                {title}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}