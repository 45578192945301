import React, { Component } from 'react';
import { default as st } from './style';
import parse from "html-react-parser";

export default class MarkerColList extends Component {
    render() {
        const { list } = this.props;

        return (
            <div className={`${st}`}>
                <ul className={`${st}__list`}>
                    {list.map((item, key) => (
                        <li key={key} className={`${st}__item`}>
                            <span className={`${st}__text`}>
                                {parse(item)}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}