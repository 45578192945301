import React, { Component } from 'react';
import { default as st } from './style';
import { ReactComponent as Logo } from './tutmee.svg';

export default class TutmeeLogo extends Component {
    render() {
        return (
            <a className={`${st}`} href={'https://tutmee.ru/'} target={'_blank'} rel='noopener noreferrer'>
                <Logo />

                <span className={`${st}__label`}>
                    LTD TutMee Web development Agency
                </span>
            </a>
        );
    }
}