import React, { Component } from 'react';
import InView from "react-intersection-observer";
import { default as st } from './style';
import TitleBlock from "../../components/TitleBlock";
import { styles } from '../../constants';
import ParallaxWrap from "../ParallaxWrap";
import { ReactComponent as StairSvg } from '../../images/bg/stair1.svg';
import MediaQuery from "react-responsive";

export default class Steps extends Component {
    renderList = (list) => {
        return (

            <div className={`${st}__list`}>
                {list.map((item, key) => (
                    <div key={key} className={`${st}__step animate animate-up`}>
                        <div className={`${st}__num rotated-text`}>
                            <span>{`0${key+1}.`}</span>
                        </div>

                        <div className={`${st}__step-title`}>
                            {item}
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    renderBg = () => {
        return (
            <InView triggerOnce={true} rootMargin={'500px 0px'}>
                {({ inView, ref }) => {
                    const imgMod = inView ? ' _load' : '';

                    return (
                        <div ref={ref} className={`${st}__bg-wrap`}>
                            <MediaQuery minWidth={`${styles.breakpoints[1000]}px`}>
                                <InView threshold={0.5} >
                                    {({ inView, ref }) => {
                                        const imgMod = inView ? ' _draw' : '';

                                        return (
                                            <div ref={ref} className={`${st}__stair${imgMod}`}>
                                                <StairSvg />
                                            </div>
                                        )
                                    }}
                                </InView>
                            </MediaQuery>

                            <ParallaxWrap className={`${st}__shavings-wrap`}>
                                <div className={`${st}__shavings${imgMod} js-layer`} data-depth={5}  />
                            </ParallaxWrap>
                        </div>
                    )
                }}
            </InView>

        )
    }

    render() {
        const { title, list, anchor='steps' } = this.props;

        return (
            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div id={anchor} ref={ref} className={`${st}${inViewMod}`}>
                            <div className={`${st}__container container`}>
                                <div className={`${st}__inner-container inner-container`}>
                                    <div className={`${st}__table grid-table`}>
                                        <div className={`${st}__col ${st}__col_left col _3-10`}>
                                            {title &&
                                                <TitleBlock
                                                    mod={` ${st}__title`}
                                                    title={title}
                                                />
                                            }
                                        </div>

                                        <div className={`${st}__col ${st}__col_right col _7-10`}>
                                            {list &&
                                                this.renderList(list)
                                            }

                                            <MediaQuery minWidth={`${styles.breakpoints[500]+1}px`}>
                                                {this.renderBg()}
                                            </MediaQuery>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>
        );
    }
}

