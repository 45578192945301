import { combineReducers } from "redux";
import { calc } from "./calc";
import { calcTotal } from "./calcTotal";
import { popup } from "./popup";

export const rootReducer = combineReducers({
    calc: calc,
    calcTotal: calcTotal,
    popup: popup,
});

