import React, { Component, Fragment } from 'react';
import { default as st } from './style';
import { Field } from 'formik';
import InputMask from 'react-input-mask';

export default class InputStyle extends Component {
    getValidationMod = (form, field, name) => {
        const
            isTouchEnd = form.touched[name],
            isErrorExist = isTouchEnd && form.errors[name],
            isValid = isTouchEnd && field.value && !isErrorExist;

        return isErrorExist ? ' _error' : (isValid ? ' _valid' : '')
    };
    
    render() {
        const {
                tag,
                name,
                placeholder,
                label,
                type = 'text',
                mask,
                mask_char = '_',
                mod=''
            } = this.props,
            TagInput = tag || 'input';
        
        return (
            <div className={`${st}${mod}`}>
                <Field name={name}>
                    {({ field, form }) => (
                        <Fragment>
                            {label && (
                                <span className={`${st}__label`}>{label}</span>
                            )}
                            {mask ? (
                                <InputMask
                                    {...field}
                                    type={type}
                                    mask={mask}
                                    placeholder={placeholder}
                                    maskChar={mask_char}
                                    className={`${st}__input${this.getValidationMod(form, field, name)}`}
                                />
                            ) : (
                                <TagInput
                                    {...field}
                                    type={type}
                                    className={`${st}__input${this.getValidationMod(form, field, name)}`}
                                    placeholder={placeholder}
                                />
                            )}
                        </Fragment>
                    )}
                </Field>
            </div>
        );
    }
}
