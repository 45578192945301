import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import validate from "../../utils/validate-yup";
import collect_schema from "../../utils/collect-schema";
import { urls } from '../../constants';
import fetchQuery from "../../utils/fetch";
import FormStructure from './FormStructure';
import { setPopupData } from "../../actions/setPopupData";
import {connect} from "react-redux";

class FormDefault extends Component {
    collectInitialValues = fields => {
        let obj = {};

        for (let i = 0; i < fields.length; i++) {
            const { name, value = '' } = fields[i];

            obj[name] = value;
        }

        return obj;
    };
    
    getYup = (fields) => {
        let schema = {};
        return Yup.object(collect_schema(schema, fields));
    };
    
    onSuccess = (actions) => {
        const { setPopupData, ty_popup_data } = this.props;

        setPopupData({
            show: true,
            type: 'ty',
            data: ty_popup_data
        });

        setTimeout(() => {
            actions.resetForm()
        }, 1000)
    };
    
    render() {
        const { fields, schema, form_handler, additionalFormData } = this.props;

        return (
            <Formik
                enableReinitialize={false}
                onSubmit={(values, actions) => {
                    let formObj = new FormData();

                    for (let prop in values) {
                        if(values.hasOwnProperty(prop)) {
                            formObj.append(prop, values[prop]);
                        }
                    }

                    if (additionalFormData) {
                        for (let prop in additionalFormData) {
                            if(additionalFormData.hasOwnProperty(prop)) {
                                const
                                    val = additionalFormData[prop],
                                    fixedVal = typeof val === 'object' ? JSON.stringify(val) : val;

                                formObj.append(prop, fixedVal);
                            }
                        }
                    }

                    fetchQuery({
                        url: form_handler || urls.form_handler,
                        method: 'POST',
                        body: formObj,
                        customSuccess: this.onSuccess.bind(this, actions)
                    });
                }}
                validate={schema ? validate(schema) : validate(this.getYup.bind(this, fields))}
                initialValues={this.collectInitialValues(fields)}
            >
                {props => (
                    <Form>
                        <FormStructure {...this.props} formikProps={props} />
                    </Form>
                )}
            </Formik>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setPopupData: obj => dispatch(setPopupData(obj))
    }
};

export default connect(null, mapDispatchToProps)(FormDefault);

