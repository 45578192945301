import { css } from 'emotion';
import { styles } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        padding: [7],
        background: 'rgba(255, 255, 255, .1)',

        '&__input-wrap': {
            position: 'relative',
        },

        '&__input': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2,
        },

        '&__input:checked ~ &__indicator': {
            '&::before': {
                background: colors.yellow
            },

            '&::after': {
                background: 'transparent'
            }
        },

        '&__input:checked:hover ~ &__indicator': {
            '&::after': {
                color: colors.yellow,
            }
        },

        '&__input:not(:checked):hover ~ &__indicator': {
            '&::before': {
                color: colors.yellow,
            }
        },

        '&__indicator': {
            position: 'relative',
            display: 'flex',

            '&::before, &::after': {
                fontFamily: styles.fontFamily.icomoon,
                lineHeight: '1',
                fontWeight: '100',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: [36],
                height: [36],
                transition: styles.transitions.default
            },

            '&::before': {
                content: '"\\e902"',
                fontSize: [14],
                color: colors.dark,
                background: 'transparent'
            },

            '&::after': {
                content: '"\\e903"',
                fontSize: [12],
                marginLeft: [5],
                color: 'rgba(255,255,255,.2)',
                background: colors.dark
            }
        }
    })
);
