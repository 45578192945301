import { css, keyframes } from 'emotion';
import { styles } from '../../constants';
const { mq } = styles;

const show = keyframes`
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
`;

const hide = keyframes`
    0% {
        opacity: 1;
    }
  
    100% {
        opacity: 0;
    }
`;

export default css(
    mq({
        ...styles.fixed(),
        width: '100%',
        height: '100%',
        zIndex: '999',
        background: '#303675',
        transition: 'transform 0s linear, opacity 0.7s ease',
        transitionProperty: 'transform, opacity',
        transitionDelay: '1.4s, 0.7s',
        opacity: '0',
        transform: 'translateX(-110%)',
        pointerEvents: 'none',
        // padding: '100px 0 0',





        '&__transition-group': {

            width: '100%',
            height: '100%',
        },

        '&__animation-wrap': {
            width: '100%',
            height: '100%',

            '&.exit-active': {
                // background: 'red'
                // ...styles.absolute(),
                animation: `${hide} .5s ease both`
            },

            '&.enter-active': {
                // background: 'green'
                ...styles.absolute(),
                animation: `${show} .5s ease both`,
                animationDelay: '1s'
            },

            '&.enter-done': {
                // background: 'green'
            }
        },






        '&::before': {
            content: '""',
            display: 'block',
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            background: styles.colors.white,
            pointerEvents: 'none',
            zIndex: '-1'
        },

        '&__container, &__close-btn': {
            opacity: '0',
            transition: 'opacity 0.7s ease',
            transitionDelay: '0s',
        },

        '&__container': {
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '100%',
            paddingLeft: 0,
            paddingRight: 0,

        },

        '&__inner-container': {
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '100%',
        },

        '&__wrap': {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: '100%',
            minHeight: '100%',
        },

        '&__content': {
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '100%',
            margin: 'auto',
            // paddingBottom: '100px'
        },

        '&__close-button-wrap': {
            ...styles.absolute('auto'),
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        },

        '&__close-btn': {
            ...styles.absolute('20px','20px','auto','auto'),
            display: 'block',
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            overflow: 'hidden',
            transition: styles.transitions.default,
            cursor: 'pointer',
            zIndex: '5',

            // '&::before': {
            //     content: '""',
            //     ...styles.absolute(),
            //     display: 'block',
            //     width: '100%',
            //     height: '100%',
            //     background: styles.colors.white,
            //     transition: styles.transitions.default,
            //     zIndex: '2'
            // },

            'span': {
                display: 'block',
                position: 'relative',
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                overflow: 'hidden',
                zIndex: '3',

                '&::before, &::after': {
                    content: '""',
                    display: 'block',
                    ...styles.absolute(),
                    height: '1px',
                    width: '40%',
                    transformOrigin: '50% 50%',
                    background: styles.colors.dark,
                    transition: styles.transitions.default
                },

                '&::before': {
                    transform: 'rotate(-45deg)'
                },

                '&::after': {
                    transform: 'rotate(45deg)'
                },
            },

            '&:hover': {
                '&::before': {
                    transform: 'scale(1.1)'
                }
            }
        },

        '&._show': {
            transitionDelay: '0.3s, 0.4s',
            transform: 'translateX(0)',
            opacity: '1',
            pointerEvents: 'auto',
        },

        '&._show &': {
            '&__container, &__close-btn': {
                opacity: '1',
                transitionDelay: '1.3s',
            },
        }
    })
);
