import { css } from 'emotion';
import { styles, grid } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        position: 'relative',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingTop: 60,
        paddingBottom: 60,

        '&__table': {
            position: 'relative',
            display: 'flex',
            flexDirection: [null, null, null, null, null, 'column'],
            alignItems: 'flex-start',
            justifyContent: ['flex-end', null, null, null, null, 'flex-start'],
            width: ['calc(100% / 12 * 8)', null, null, 'calc(100% / 12 * 10)', 'calc(100% - 60px)'],
            maxWidth: [940, 860],
            margin: 'auto',
        },

        '&__col': {
            '&_left': {
                ...styles.absolute(0,'auto','auto', 0),
                position: ['absolute', null, null, null, null, 'relative'],
                width: ['calc(100% / 6 * 3)', null, 'calc(100% / 12 * 5)', null, 'calc(100% / 12 * 4 + 20px)', '100%'],
                paddingTop: [grid.default[5][0], grid.default[5][1], grid.default[5][2], grid.default[5][3], grid.default[5][4], 0],
                zIndex: 2
            },

            '&_right': {
                width: ['calc(100% / 6 * 4)', null, null, null, null, '100%'],
                zIndex: 1
            }
        },

        '&._wide &': {
            '&__table': {
                width: ['calc(100% / 12 * 8)', null, 'calc(100% / 12 * 10)', null, 'calc(100% - 60px)'],
                maxWidth: 'none'
            },

            '&__col': {
                '&_left': {
                    width: ['calc(100% / 12 * 4)', null, 'calc(100% / 12 * 5 - 20px)', null, 'calc(100% / 12 * 4 + 20px)', '100%'],
                },

                '&_right': {
                    width: ['calc(100% / 8 * 6)', null, 'calc(100% / 12 * 8)', null, null, '100%'],
                }
            },
        }
    })
);
