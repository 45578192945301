import { actions } from "../constants";

const initialState = {

};

export function calc(state = initialState, action) {
    switch (action.type) {
        case actions.calc.SET_CALC_DATA: {
            const { type, name, val, parameters } = action.payload;
            let newObj = {...state};

            if (!newObj[type]) newObj[type] = {};
            if (!newObj[type][name]) newObj[type][name] = {}
            if (val) newObj[type][name]['val'] = val;
            if (parameters) newObj[type][name]['parameters'] = parameters;

            return {...newObj};
        }

        case actions.calc.REMOVE_CALC_DATA: {
            const { type, name } = action.payload;
            let newObj = {...state};

            if (newObj[type] && newObj[type][name]) delete newObj[type][name];

            return {...newObj};
        }

        case actions.calc.RESET_CALC_DATA: {
            return {};
        }

        default: {
            return state
        }
    }
}
