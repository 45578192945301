import { css } from 'emotion';
import { styles } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        overflow: 'hidden',
        width: ['calc(25% - 20px)', null, 'calc(50% - 20px)'],
        margin: '20px 10px 0',

        '&::before': {
            zIndex: 3
        },

        '&:hover &': {
            '&__circle': {
                transition: 'width .4s ease-in-out, opacity .1s ease-in-out',
                width: '300%',
            }
        },

        '&__circle': {
            position: 'absolute',
            display: 'block',
            borderRadius: '50%',
            transform: 'translate(-50%,-50%)',
            backgroundColor: colors.yellow,
            opacity: .7,
            transition: 'width .6s ease-in-out, opacity .4s ease-in-out, top .1s ease, left .1s ease',
            width: 0,
            zIndex: 2,

            ':after': {
                content: "''",
                display: 'block',
                paddingTop: '100%'
            }
        },


        '&__img-wrap': {
            display: 'block',
            position: 'relative',
            ...styles.rh(),
            zIndex: 1
        },

        '&__img': {
            ...styles.of(),
            ...styles.absolute(),
        }
    })
);
