import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { default as st } from './style';
import { connect } from "react-redux";
import { setPopupData } from "../../actions/setPopupData";
import ParallaxWrap from "../../containers/ParallaxWrap";


class Button extends Component {
    constructor(props) {
        super(props);
        this.mouse_handler = this.mouse_handler.bind(this);
        this.btn = React.createRef();
        this.state = {
            left: 0,
            top: 0
        };
    }

    mouse_handler = e => {
        let el_event = e.nativeEvent,
            btn_coords = ReactDOM.findDOMNode(this.btn.current).getBoundingClientRect(),
            rel_x = el_event.x - btn_coords.x,
            rel_y = el_event.y - btn_coords.y;

        this.setState({
            left: rel_x,
            top: rel_y
        });
    };

    base_tag = tag => {
        switch (tag) {
            case 'button':
                return 'button';
            default:
                return 'div';
        }
    };

    clickHandler = (target) => {
        if (target) {
            const { setPopupData, additionalFormData } = this.props;


            setPopupData({
                show: true,
                type: target,
                additionalFormData: additionalFormData
            });
        }
    };

    render() {
        const
            { tag, href, text, mod='', target, bordered, shadow } = this.props,
            borderedMod = bordered ? ' _bordered' : '',
            shadowMod = shadow ? ' _shadow' : '',
            { top, left } = this.state,
            circle_styles = {
                left: left,
                top: top
            },
            root_class = `${st}${mod}${borderedMod}${shadowMod}`;

        const BaseTag = this.base_tag(tag);

        return (
            shadow ?
                <BaseTag
                        className={root_class}
                        to={href}
                        onClick={() => {
                            this.clickHandler(target)
                        }}
                    >
                        <ParallaxWrap
                            className={`${st}__parallax-wrap`}
                            settings={{
                                relativeInput: true,
                                hoverOnly: true,
                                limitX: 3,
                                limitY: 5,
                            }}
                        >
                            <div className={`${st}__shadow-wrap`}>
                                <div className={`${st}__shadow js-layer`} data-depth={5} />
                            </div>

                        </ParallaxWrap>

                        <div className={`${st}__wrapper`}>
                            {text}
                        </div>
                    </BaseTag>

                :
                    <BaseTag
                        className={root_class}
                        to={href}
                        onMouseEnter={this.mouse_handler}
                        onMouseLeave={this.mouse_handler}
                        ref={this.btn}
                        onClick={() => {
                            this.clickHandler(target)
                        }}
                    >
                        <div className={`${st}__circle`} style={circle_styles} />

                        <div className={`${st}__wrapper`}>
                            {text}
                        </div>
                    </BaseTag>


        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPopupData: obj => dispatch(setPopupData(obj))
    }
};

export default connect(null, mapDispatchToProps)(Button);

