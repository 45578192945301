import styles from "./styles";

const common = {
    linkStyle: {
        color: styles.colors.yellow,
        transition: styles.transitions.default,

        '&:hover': {
            color: styles.colors.dark
        }
    },
};

export default common;
