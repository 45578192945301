import React, { Component } from 'react';
import { default as st } from './style';

export default class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.input = React.createRef();
    }

    onChange = (e) => {
        const
            { title, text, name, value, calc_type, callback } = this.props,
            checked = e.target.checked;

        if (callback) {
            callback({
                title: title,
                text: text,
                name: name,
                val: checked ? value : 'remove',
                type: calc_type
            })
        }
    }

    render() {
        const
            { name, value, mod='' } = this.props;

        return (
            <div className={`${st}${mod}`} >
                <div className={`${st}__input-wrap`} >
                    <input
                        ref={this.input}
                        onClick={this.onChange}
                        type='checkbox'
                        name={name}
                        defaultValue={value}
                        className={`${st}__input`}
                    />

                    <div className={`${st}__indicator`} />

                </div>
            </div>
        );
    }
}
