import React, { Component } from 'react';
import { default as st } from './style';
import InView from "react-intersection-observer";
import parse from "html-react-parser";
import TitleBlock from "../../components/TitleBlock";
import BorderSubstrate from "../BorderSubstrate";
import ClosePopupButton from "../ClosePopupButton";

export default class TyPopupContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        }

        this.timer = null;
    }

    onChange = (show) => {
        const delay = show ? 1000 : 0;

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.setState({
                active: show
            })
        }, delay)
    }

    render() {
        const { title, text } = this.props;

        return (
            <InView onChange={this.onChange}>
                {({ inView, ref }) => {
                    const
                        { active } = this.state,
                        inViewMod = active ? ' _in-view' : '';

                    return (
                        <div ref={ref} className={`${st}${inViewMod}`}>
                            <div className={`${st}__container`}>
                                <div className={`${st}__text-wrap`}>
                                    <div className={'left-col-container'}>
                                        <TitleBlock title={title} />
                                    </div>

                                    <div className={`${st}__text animate-d animate-up`}>
                                        {parse(text)}
                                    </div>
                                </div>

                                <BorderSubstrate
                                    className={`${st}__border-wrap${inViewMod}`}
                                >
                                    <ClosePopupButton />
                                </BorderSubstrate>
                            </div>
                        </div>
                    )
                }}
            </InView>

        );
    }
}

