import React, { Component } from "react";
import MediaQuery from "react-responsive";
import BorderSubstrate from "../BorderSubstrate";
import { default as st } from "./style";
import Slider from "react-slick";
import { styles } from "../../constants";
import {setPopupData} from "../../actions/setPopupData";
import {connect} from "react-redux";
import InView from "react-intersection-observer";
import Button from "../Button";
const { breakpoints } = styles;

class PortfolioSlider extends Component {
    constructor(props) {
        super(props);

        this.disableLinks = false;
        this.clickPos = 0;

        this.sliderSettings = {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true,
            speed: 1000,
            arrows: false,
            fade: false,
            centerPadding: "0",
            responsive: [
                {
                    breakpoint: breakpoints["1300"] + 1,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: breakpoints["500"] + 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
    }

    toggleLinks = bool => {
        this.disableLinks = bool;
    };

    handleOnMouseDown = e => {
        this.clickPos = e.pageX;
    };

    callPopup = (e, slider) => {
        const diff = Math.abs(this.clickPos - e.pageX) > 5;

        if (!this.disableLinks && !diff) {
            const { setPopupData } = this.props;

            setPopupData({
                show: true,
                type: 'portfolio',
                data: slider
            });
        }
    }

    renderItemContent = (img, title, index, inView) => {
        return (
            <React.Fragment>
                <div className={`${st}__btn-wrap`}>
                    <Button
                        text="Посмотреть работы"
                        shadow={true}
                    />
                </div>

                <div className={`${st}__count rotated-text`}>
                    <span>{index}</span>
                </div>

                <div className={`${st}__img-wrap`}>
                    {inView && <img className={`${st}__img`} src={img} alt={img} draggable="false"/>}
                </div>

                <div className={`${st}__title-wrap`}>
                    <h3 className={`${st}__title`}>{title}</h3>
                </div>
            </React.Fragment>
        )
    }

    renderItems = (list, counterStart, inView) => {
        return (
            list.map(({title, img, slider}, key) => {
                const
                    itemIndex = counterStart + key + 1,
                    prettifyIndex = itemIndex < 10 ? `0${itemIndex}.` : `${itemIndex}.`;

                return (
                    <MediaQuery key={key} minWidth={breakpoints["1000"]}>
                        {matches =>
                            matches ? (
                                <BorderSubstrate
                                    className={`${st}__item`}
                                    onClick={e => {this.callPopup(e, slider)}}
                                    onMouseDown={e => this.handleOnMouseDown(e)}
                                >
                                    {this.renderItemContent(img, title, prettifyIndex, inView)}
                                </BorderSubstrate>
                            ) : (
                                <div
                                    className={`${st}__item`}
                                    onClick={e => {this.callPopup(e, slider)}}
                                    onMouseDown={e => this.handleOnMouseDown(e)}
                                >
                                    {this.renderItemContent(img, title, prettifyIndex, inView)}
                                </div>
                            )
                        }
                    </MediaQuery>
                )
            })
        )
    }

    render() {
        const { list, counterStart } = this.props;

        return (
            <div className={`${st}`}>
                <InView triggerOnce={true} rootMargin={'500px 0px'}>
                    {({ inView, ref }) => (
                        <div ref={ref} className={`${st}__container`}>
                            <Slider
                                beforeChange={this.toggleLinks.bind(this, true)}
                                onSwipe={this.toggleLinks.bind(this, true)}
                                afterChange={this.toggleLinks.bind(this, false)}
                                className={`${st}__list`}
                                {...this.sliderSettings}
                            >
                                {
                                    this.renderItems(list, counterStart, inView)
                                }
                            </Slider>
                        </div>
                    )}
                </InView>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setPopupData: obj => dispatch(setPopupData(obj))
    }
};

export default connect(null, mapDispatchToProps)(PortfolioSlider);

