import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        paddingTop: grid.default[0],

        '&__list': {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: ['calc(100% + 20px)'],
            left: '-10px',
            marginTop: [-50, null, null, null, -40]
        },

        '&__item': {
            position: 'relative',
            marginTop: [50, null, null, null, 40],
            width: ['calc(25% - 20px)', 'calc(50% - 20px)', null, null, null, 'calc(100% - 20px)'],
            marginLeft: '10px',
            marginRight: '10px',
        },

        '&__icon-wrap': {
            marginBottom: grid.default[1],
            display: 'block',
            position: 'relative',
            width: '100%',
            height: [50, null, 45]
        },

        '&__icon': {
            display: 'block',
            position: 'relative',
            maxHeight: '100%',
            maxWidth: '100%',
            marginRight: 'auto'
        },

        '&__text': {
            position: 'relative',
            display: 'block',
            ...fonts.p2,
            color: styles.colors.dark,
            maxWidth: 225
        }
    })
);
