import React, { Component } from 'react';
import { default as st } from './style';
import TitleBlock from "../../components/TitleBlock";
import TextBlock from "../../components/TextBlock";
import Button from "../../components/Button";
import InView from 'react-intersection-observer';
import PictureBlock from "../../components/PictureBlock";
import MediaQuery from "react-responsive";
import styles from "../../constants/styles";

export default class About extends Component {
    render() {
        const { title, bg_img, content, anchor='about' } = this.props;

        return (
            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div id={anchor} ref={ref} className={`${st}${inViewMod}`}>
                            <div className={`${st}__container container`}>
                                <div className={`${st}__inner-container inner-container`}>

                                    <div className={`${st}__table grid-table`}>
                                        <div className={`${st}__col ${st}__col_left col _5-10`}>
                                            <div className={`${st}__content`}>
                                                {title &&
                                                    <TitleBlock
                                                        title={title}
                                                    />
                                                }

                                                {bg_img &&
                                                    <MediaQuery maxWidth={`${styles.breakpoints[1300]}px`}>
                                                        <InView triggerOnce={true} rootMargin={'500px 0px'}>
                                                            {({ inView, ref }) => (
                                                                <div ref={ref} className={`${st}__img-wrap animate-img`}>
                                                                    {inView && <PictureBlock images={bg_img} />}
                                                                </div>
                                                            )}
                                                        </InView>
                                                    </MediaQuery>
                                                }

                                                {content &&
                                                    <TextBlock content={content} />
                                                }

                                                <div className={`${st}__button-wrap`}>
                                                    <Button
                                                        text="Хочу бесплатную консультацию"
                                                        target="feedback"
                                                        shadow={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {bg_img &&
                                            <MediaQuery minWidth={`${styles.breakpoints[1300]+1}px`}>
                                                <div className={`${st}__col ${st}__col_right col _5-10`}>

                                                    <InView triggerOnce={true} rootMargin={'500px 0px'}>
                                                        {({ inView, ref }) => (
                                                            <div ref={ref} className={`${st}__img-wrap animate-img`}>
                                                                {inView && <PictureBlock images={bg_img} />}
                                                            </div>
                                                        )}
                                                    </InView>
                                                </div>
                                            </MediaQuery>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>
        );
    }
}

