import React, { Component } from 'react';
import { default as st } from './style';


export default class Hamburger extends Component {
    callback = () => {
        const { callback } = this.props;

        if (callback) callback();
    }

    render() {
        return (
            <div className={`${st}`} onClick={this.callback}>
                <div className={`${st}__lines`} />
            </div>
        );
    }
}
