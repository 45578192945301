import React, { Component } from 'react';
import { Picture } from 'react-responsive-picture';

export default class PictureBlock extends Component {
    render() {
        const { images } = this.props;

        return (
            <Picture
                sources = {images}
            />
        );
    }
}

