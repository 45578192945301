import React, { Component } from 'react';
import { default as st } from './style';

export default class IconList extends Component {
    render() {
        const { list } = this.props;

        return (
                <div className={`${st}`}>
                    <div className={`${st}__list`}>
                        {list.map(({text, img}, key) => (
                            <div key={key} className={`${st}__item`}>
                                <div className={`${st}__icon-wrap`}>
                                    <img src={img} className={`${st}__icon`} alt={img} />
                                </div>

                                <p className={`${st}__text`}>
                                    {text}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
        );
    }
}