function urls() {
    const stage = (process && process.env && process.env.REACT_APP_STAGE) || 'live';

    return urlsObj[stage]
}

const
    hostLocalFrontend = 'http://easy-step-local/',
    hostLocalBackend = 'http://easy-step/';


const urlsObj = {
    live: {
        homepage: 'data/homepage.json',
        form_handler: 'form-handler.php',
    },

    backend: {
        homepage: 'data/homepage.json',
        form_handler: hostLocalBackend + 'public/form-handler.php',
    },
    
    frontend: {
        homepage: 'data/homepage.json',
        form_handler: hostLocalFrontend + 'public/form-handler.php',
    }
};

export default urls(window.location);
