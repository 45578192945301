import facepaint from 'facepaint';

const styles = {
    breakpoints: {
        '1920': 1920,
        '1800': 1800,
        '1500': 1500,
        '1300': 1300,
        '1200': 1200,
        '1000': 1000,
        '900': 900,
        '700': 700,
        '500': 500,
    },

    headerHeight: [118, 115, 127, 101, 70],

    bp: (val) => `@media (max-width: ${styles.breakpoints[val]}px)`,
    bpm: (val) => `@media (min-width: ${styles.breakpoints[val]+1}px)`,
    bpb: (from, to) => `@media (min-width: ${styles.breakpoints[from]+1}px) and (max-width: ${styles.breakpoints[to]}px)`,
    bpt: () => `@media (hover: none) and (pointer: coarse)`,
    bpnt: () => `@media not all and (hover: none)`,
    
    colors: {
        black: '#000',
        white: '#fff',
        dark: '#453838',
        d2: '#7C6B6B',
        yellow: '#FFCD34',
        y2: '#FFAE34',
        gray1: '#FAFAFA',
        gray2: '#DBDBDB',
        gray3: '#676767',
        carrot: '#FF6A49'
    },

    fontFamily: {
        roboto: 'roboto',
        bebasneue: 'bebasneue',
        icomoon: 'icomoon',
    },

    transitions: {
        default: 'all 0.3s ease',
        draw: '5s cubic-bezier(0, 0.05, 0.1, 0.15)',
        draw2: '5s cubic-bezier(0, 0.05, 0.07, -0.14)'
    },

    // containerStyles: (parameters) => {
    //     const { left=false, right=false } = parameters;
    //
    //     let styleObj = {
    //         '&__container': {
    //
    //         },
    //
    //         '&__inner-container': {
    //
    //         }
    //     };
    //
    //     return styleObj
    // },

    // filter: grayscale(100%) brightness(24%) sepia(96%) hue-rotate(-413deg) saturate(64%) contrast(1);

    setDelays: (data) => {
        const { base=0, count=0, delay=100, type='transition', selectorMod='', reverse=false, nth='nth-of-type' } = data;
        let stylesObj = {};

        for (let i = 1; i <= count; i++) {
            stylesObj[`&:${nth}(${i}n)${selectorMod}`] = {
                [`${type}Delay`]: reverse ? `${base + ((delay * count) - (delay * i))}ms` : `${base + (delay * i)}ms`
            }
        }

        return stylesObj;
    },

    absolute: (top='0',right='0',bottom='0',left='0') => {
        return {
            position: 'absolute',
            top: top,
            right: right,
            bottom: bottom,
            left: left,
            margin: 'auto'
        }
    },

    fixed: (top='0',right='0',bottom='0',left='0') => {
        return {
            position: 'fixed',
            top: top,
            right: right,
            bottom: bottom,
            left: left,
            margin: 'auto'
        }
    },

    rh: (mlt=1) => {
       return {
           '&::after': {
               content: '""',
               display: 'block',
               paddingTop: `${100 * mlt}%`
           }
       }
    },
    
    of: (obj={}) => {
        const {pos='center center', fit='cover'} = obj;
        
        return {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: fit,
            objectPosition: pos,
        }
    }
};

styles.mq = facepaint([
    styles.bp(styles.breakpoints['1800']),
    styles.bp(styles.breakpoints['1300']),
    styles.bp(styles.breakpoints['1000']),
    styles.bp(styles.breakpoints['700']),
    styles.bp(styles.breakpoints['500'])
]);

export default styles;
