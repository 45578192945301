import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        paddingTop: grid.default[3],
        paddingBottom: grid.default[3],
        borderTop: `1px solid ${colors.gray2}`,

        '&:first-child': {
            borderTop: [null, null, 'none'],
        },

        '&:last-child': {
            borderBottom: `1px solid ${colors.gray2}`,
        },

        '&__title': {
            ...fonts.p2,
            cursor: 'pointer',
            transition: styles.transitions.default,

            '&:hover': {
                color: colors.yellow
            }
        },

        '&__title-wrap': {
            position: 'relative',
            paddingLeft: [51, null, null, null, 41]
        },

        '&__content-wrap': {
            paddingLeft: [51, null, null, null, 41]
        },

        '&__content': {
            paddingTop: grid.default[2]
        },

        '&__indicator': {
            ...styles.absolute(0, 'auto', 'auto'),
            width: [21],
            height: [21],

            '&::before, &::after': {
                ...styles.absolute(),
                content: '""',
                width: '100%',
                height: [3],
                background: colors.yellow,
            },

            '&::after': {
                transformOrigin: '50% 50%',
                transition: styles.transitions.default,
                transform: 'rotate(-90deg)'
            },

            '&._active': {
                '&::after': {
                    // transform: 'rotate(0deg)'
                    transform: 'rotate(-90deg) scaleX(0)'
                },
            }
        },


    })
);
