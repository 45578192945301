import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';

const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: "flex-start",

        '*': {
            marginTop: grid.default[1],

            '&:first-child': {
                marginTop: 0
            }
        },

        '& > a + a': {
            marginTop: grid.default[0],
        },
        
        h1: {
            ...fonts.h1
        },

        h2: {
            ...fonts.h2
        },

        h3: {
            ...fonts.h3
        },

        img: {
            display: 'block',
            maxWidth: '100%',
            marginLeft: '0',
            marginRight: 'auto'
        },

        'ul, ol': {
            li: {
                ...fonts.p3,
            }
        },

        p: {
            ...fonts.p3,
            
            a: {
                display: 'inline-block',
                marginBottom: 0
            }
        },

        a: {
            ...fonts.p3,
            display: 'block',
            color: colors.yellow,
            
            '&:visited': {
                color: colors.yellow
            },
            
            '&:hover': {
                textDecoration: 'underline'
            }
        },

        '&._clear-margins': {
            '*': {
                marginTop: 0,
            },

            '& > a + a': {
                marginTop: 0,
            },
        }
    })
);
