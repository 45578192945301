import { actions } from "../constants";

const initialState = {
    show: false,
    content: null
};

export function popup(state = initialState, action) {
    switch (action.type) {
        case actions.popup.SET_POPUP_DATA: {
            return {...state, ...action.payload};
        }
        default: {
            return state
        }
    }
}
