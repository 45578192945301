import { css } from "emotion";
import { styles, fonts, grid } from "../../constants";
const { mq } = styles;

export default css(
    mq({
        marginBottom: grid.default[3],

        h2: {
            ...fonts.h2
        },

        h3: {
            ...fonts.h3,
            marginTop: grid.default[3]
        },

        '&__title': {
            display: 'flex',
            flexDirection: ['column', null, 'row'],
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',

            '&._icon': {
                span: {
                    marginRight: 15
                }
            }
        },

        '&__icon-link': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: styles.colors.yellow,

            ':before': {
                fontSize: [34, 28],
                marginRight: ['.5em', '.2em'],
            }
        },

        '._white &': {
            "&__title, &__subtitle": {
                color: styles.colors.white
            },
        }
    })
);
