import React, { Component } from 'react';
import { default as st } from './style';
import {InView} from "react-intersection-observer";
import TitleBlock from "../../components/TitleBlock";
import MarkerColList from "../../components/MarkerColList";
import IconList from "../../components/IconList";
import PictureBlock from "../../components/PictureBlock";

export default class Advantages extends Component {
    render() {
        const { title, bg_img, description_list, icons_list, anchor='advantages' } = this.props;

        return (
            <div id={anchor} className={`${st}`}>
                <div className={`${st}__container container`}>
                    <div className={`${st}__inner-container inner-container`}>
                        <div className={`${st}__table grid-table`}>
                            <div className={`${st}__col ${st}__col_left col _3-10`}>
                                {title &&
                                    <InView rootMargin={'-20% 0% -20%'}>
                                        {({ inView, ref }) => {
                                            const inViewMod = inView ? ' _in-view' : '';

                                            return (
                                                <div ref={ref} className={`${st}__title-wrap ${inViewMod}`}>
                                                    <TitleBlock
                                                        title={title}
                                                    />
                                                </div>
                                            )
                                        }}
                                    </InView>
                                }

                                {bg_img &&
                                    <InView rootMargin={'-20% 0% -20%'}>
                                        {({ inView, ref }) => {
                                            const inViewMod = inView ? ' _in-view' : '';

                                            return (
                                                <div ref={ref} className={`${st}__img-wrap animate-img${inViewMod}`}>
                                                    <PictureBlock images={bg_img} />
                                                </div>
                                            )
                                        }}
                                    </InView>

                                }
                            </div>
                            <div className={`${st}__col ${st}__col_right col _7-10`}>
                                {description_list &&
                                    <MarkerColList list={description_list} />
                                }

                                {icons_list &&
                                    <IconList list={icons_list} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

