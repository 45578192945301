import React, { Component } from 'react';
import { default as st } from './style';
import parse from "html-react-parser";
import {InView} from "react-intersection-observer";
import Button from "../../components/Button";

export default class Intro extends Component {
    renderTitle = (title) => {
        const { text, suffix } = title;

        return (
            <div className={`${st}__title-wrap animate-title-d`}>
                <h1 className={`${st}__title`}>
                    {text}
                    <span className={'suffix-wrap'}>
                        <span className={'suffix-text'}>{parse(suffix)}</span>
                    </span>
                </h1>
            </div>
        )
    }

    render() {
        const { title, bottom, anchor='intro' } = this.props;

        return (
            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div id={anchor} ref={ref} className={`${st}${inViewMod}`}>
                            <div className={`${st}__container container`}>
                                <div className={`${st}__inner-container inner-container`}>
                                    <div className={`${st}__content`}>
                                        {title &&
                                            this.renderTitle(title)
                                        }

                                        <div className={`${st}__button-wrap`}>
                                            <Button
                                                text="заказать персональный расчет"
                                                shadow={true}
                                                target="calc"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {bottom &&
                                <div className={`${st}__bottom`}>
                                    {bottom.map(({text, img}, key) => (
                                        <div key={key} className={`${st}__icon-subtitle animate-d animate-up`}>
                                            <div className={`${st}__icon-wrap`}>
                                                <img src={img} alt={img} />
                                            </div>

                                            <p>{text}</p>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    )
                }}
            </InView>


        );
    }
}

