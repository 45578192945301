import React, { Component } from 'react';
import { default as st } from './style';
import NavItem from "../NavItem";

export default class Nav extends Component {
    render() {
        const { list, scrollSettings, callback } = this.props;

        return (
            <div className={`${st}`}>
                <div className={`${st}__list`}>
                    {list.map((item, key) => (
                        <NavItem key={key} {...item} scrollSettings={scrollSettings} callback={callback} />
                    ))}
                </div>
            </div>
        );
    }
}