import React, { Component } from 'react';
import { default as st } from './style';
import Counter from "../../components/Counter";
import Radio from "../../components/Radio";
import Checkbox from "../../components/Checkbox";
import ImgRadio from "../../components/ImgRadio";
import {connect} from "react-redux";
import {setCalcData, removeCalcData} from "../../actions/setCalcData";
import {setCalcTotal} from "../../actions/setCalcTotal";

class CalculatorFields extends Component {
    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.dataForSend = {};
    }

    onChange = (data) => {
        const { setCalcData, removeCalcData } = this.props;
        const { val, title, text } = data;

        if (val === 'remove') {
            delete this.dataForSend[title];
            removeCalcData(data)
        } else {
            this.dataForSend = {
                ...this.dataForSend,
                [title]: text
            }
            setCalcData(data);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { calc } = this.props;

        if (!Object.keys(calc).length) {
            this.form.current.reset();
            this.dataForSend = {};
        }

        this._calcTotal(calc);
    }

    _getWork = (config) => {
        return config && config['work'] && config['work']['val']
    }

    _getBase = (base) => {
        return base && base['stair'] && base['stair']['val'] && base['stair']['val']['prices']
    }

    _getBaseMlt = (base_mlt) => {
        return base_mlt && base_mlt['material'] && base_mlt['material']['val']
    }

    _getSum = (sum) => {
        let sumTotal = 0;

        for (let prop in sum) {
            if (sum.hasOwnProperty(prop)) {
                sumTotal += sum[prop].val
            }
        }

        return sumTotal
    }

    _getRangeMlt = (range) => {
        let mltTotal = 1;

        for (let prop in range) {
            if (range.hasOwnProperty(prop)) {
                const { val, parameters } = range[prop];

                for (let i = 0; i < parameters.length; i++) {
                    const { from, to, mlt } = parameters[i];

                    if ((val >= from) && (val <= to)) mltTotal *= mlt;
                }
            }
        }

        return mltTotal
    }

    _calcTotal = (data) => {
        const
            { setCalcTotal } = this.props,
            { sum, base, config, base_mlt, range } = data,
            basePrices = this._getBase(base),
            workType = this._getWork(config);

        let total = 0;

        if (basePrices && workType) {
            const
                baseMlt = this._getBaseMlt(base_mlt),
                RangeMlt = this._getRangeMlt(range),
                sumTotal = this._getSum(sum);

            total = basePrices[workType];

            if (baseMlt) total = total * baseMlt;
            if (sumTotal) total += sumTotal;

            total *= RangeMlt;
        }

        setCalcTotal({
            parameters: this.dataForSend,
            total: Math.ceil(total)
        })
    }

    _getVal = (data) => {
        const
            { calc_type, name } = data,
            { calc } = this.props;

        return calc[calc_type] && calc[calc_type][name] && calc[calc_type][name]['val']
    }

    renderCounters = (data) => {
        return (
            <div className={`${st}__row`}>
                <div className={`${st}__fields-set _separate`}>
                    {data.map(({title, data}, key) => (
                        <div key={key} className={`${st}__col _1-3`}>
                            <div className={`${st}__title`}>{title}</div>

                            <Counter {...data} callback={this.onChange} title={title}/>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    renderRadioRows = (data) => {
        const { title, items } = data;

        return (
            <div className={`${st}__row`}>
                <div className={`${st}__title`}>{title}</div>

                <div className={`${st}__fields-set`}>
                    {items.map((item, key) => {
                        const
                            { disable_if } = item,
                            disable = disable_if ? (this._getVal(disable_if) === disable_if.val) : false;

                        return (
                            <Radio title={title} callback={this.onChange} key={key} {...item} disable={disable} />
                        )
                    })}
                </div>
            </div>
        )
    }

    renderTypes = (data) => {
        const
            { title, types, show_if } = data,
            calcVal = this._getVal(show_if);

        if (calcVal) {
            return (
                <div className={`${st}__row`}>
                    <div className={`${st}__title`}>{title}</div>

                    <div className={`${st}__fields-set _separate _top`}>
                        {types[calcVal].map((item, key) => (
                            <div key={`${calcVal}${key}`} className={`${st}__col _1-4`}>
                                <ImgRadio title={title} {...item} mod={' _icon'} callback={this.onChange} />
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
    }

    renderTurn = (data) => {
        const
            { title, items, show_if } = data,
            calcVal = this._getVal(show_if);

        if (calcVal) {
            if (show_if['value_one_of'].indexOf(calcVal.type) !== -1) {
                return (
                    <div className={`${st}__row`}>
                        <div className={`${st}__title`}>{title}</div>

                        <div className={`${st}__fields-set _separate _top`}>
                            {items.map((item, key) => (
                                <div key={key} className={`${st}__col _1-4`}>
                                    <ImgRadio title={title} key={key} {...item} callback={this.onChange}/>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
        }
    }

    renderCheckboxCols = (data) => {
        return (
            <div className={`${st}__row`}>
                <div className={`${st}__fields-set _separate`}>
                    {data.map(({title, data}, key) => (
                        <div key={key} className={`${st}__col _1-4`}>
                            <div className={`${st}__title`}>{title}</div>

                            <Checkbox title={title} {...data} callback={this.onChange}/>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    render() {
        const { sizes, work, furniture, material, fencing, stairs, turn, additional } = this.props;

        return (
            <form ref={this.form} className={`${st}`}>
                {sizes &&
                    this.renderCounters(sizes)
                }

                {work &&
                    this.renderRadioRows(work)
                }

                {furniture &&
                    this.renderRadioRows(furniture)
                }

                {material &&
                    this.renderTypes(material)
                }

                {fencing &&
                    this.renderRadioRows(fencing, ' _1-5')
                }

                {stairs &&
                    this.renderRadioRows(stairs)
                }

                {turn &&
                    this.renderTurn(turn)
                }

                {additional &&
                    this.renderCheckboxCols(additional)
                }
            </form>
        )
    }
}




const mapDispatchToProps = dispatch => {
    return {
        setCalcData: obj => dispatch(setCalcData(obj)),
        removeCalcData: obj => dispatch(removeCalcData(obj)),
        setCalcTotal: obj => dispatch(setCalcTotal(obj)),
    }
};


const mapStateToProps = store => {
    return {
        calc: store.calc
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorFields);
