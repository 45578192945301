import { css } from 'emotion';
import { styles, grid } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        marginBottom: grid.default[8],
        minHeight: 600,

        '&__col': {
            paddingTop: [grid.default[5][0], grid.default[5][1], 0],
            zIndex: 2,
        },

        '&__content': {
            width: ['calc(100% / 5 * 4)', null, '100%']
        },

        '&__button-wrap': {
            marginTop: grid.default[4]
        },

        '&__img-wrap': {
            position: 'relative',
            width: '100%',
            height: [600, null, 470, 373, 196],
            marginBottom: [null, null, 60, null, 50],
            zIndex: 1,

            picture: {
                display: 'block',
                position: 'relative',
                width: '100%',
                height: '100%',

                img: {
                    ...styles.of(),
                }
            }
        },
    })
);
