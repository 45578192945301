import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        background: [null, null, styles.colors.dark],
        width: '100%',
        zIndex: 10,

        '&__label': {
            ...styles.absolute(0,'auto','auto', ['-.1em', '-0.35em']),
            ...fonts.p1,
            userSelect: 'none',

            span: {
                color: colors.white,
                opacity: .1,
            }
        },

        '&__container': {
            position: 'relative',
            paddingTop: [grid.default[7][0], grid.default[7][0], 22, null, 10],
            paddingBottom: [grid.default[6][0], grid.default[6][0], 22, null, 10],
            paddingLeft: [55, null, 40, 30, 20],
            paddingRight: [55, null, 40, 30, 10],
            marginLeft: ['auto', 0],
            marginRight: [0, 'auto'],
            width: ['calc(100% / 2.5 * 2)', null, '100%'],
            display: 'flex',
            flexDirection: ['column', null, 'row'],
            justifyContent: ['flex-start', null, 'space-between'],
            alignItems: ['flex-start', null, 'center'],
        },

        '&__price': {
            ...fonts.h2,
            color: colors.white,
            marginBottom: [grid.default[3][0], grid.default[3][1], 0],
            width: [null, null, 'calc(100% / 3 - 20px)', 'auto'],
            flex: [null, null, null, '1 0 0']
        },

        '&__btn-wrap': {
            marginBottom: [grid.default[3][0], grid.default[3][1], 0],
            width: [null, null, 'calc(100% / 3 - 20px)', 'auto'],
            flex: [null, null, null, '0 1 0']
        },

        '&__links-wrap': {
            width: [null, null, 'calc(100% / 3 - 20px)']
        },
    })
);
