import { css } from 'emotion';
import { styles } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        position: 'relative',
        display: 'block',
        width: '100%',
        overflow: 'hidden',

        '&__list': {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            position: 'relative',
            width: 'calc(100% + 20px)',
            left: -10,
            marginTop: -20
        }
    })
);
