import React, { Component } from "react";
import { default as st } from "./style";
import Slider from "react-slick";

export default class ImgSlider extends Component {
    constructor(props) {
        super(props);

        this.sliderSettings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            speed: 1000,
            arrows: true,
            fade: false,
            centerPadding: "0"
        };
    }

    renderImgSlides = (list) => {
        return (
            list.map((array, key) => {
                const imgMod = array.length > 1 ? ' _1-2' : '';

                return (
                    <div key={key} className={`${st}__img-slide`}>
                        <div key={key} className={`${st}__img-container`}>
                            {array.map((url, key) => (
                                <div key={key} className={`${st}__img-wrap${imgMod}`}>
                                    <img src={url} className={`${st}__img`} alt={url} />
                                </div>
                            ))}
                        </div>
                    </div>
                )
            })
        )
    }

    render() {
        const { list } = this.props;

        return (
            <div className={`${st}`}>
                {
                    list.length > 1
                        ?
                            <Slider
                                className={`${st}__img-list`}
                                {...this.sliderSettings}
                            >
                                {
                                    this.renderImgSlides(list)
                                }
                            </Slider>
                        :
                            <div className={`${st}__img-list`}>
                                {
                                    this.renderImgSlides(list)
                                }
                            </div>
                }
            </div>
        )
    }
}

