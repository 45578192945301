import React, { Component } from 'react';
import { default as st } from './style';

export default class ImgRadio extends Component {
    onChange = (e, setVal) => {
        const { title, text, name, value, calc_type, callback } = this.props;

        if (callback) callback({
            title: title,
            text: text,
            name: name,
            val: setVal || value,
            type: calc_type
        })
    }

    componentWillUnmount() {
        this.onChange(null, 'remove')
    }

    render() {
        const { img, text, name, value, mod='' } = this.props;

        return (
            <div className={`${st}${mod}`} >
                <div className={`${st}__input-wrap`} >
                    <input
                        onClick={this.onChange}
                        type='radio'
                        name={name}
                        defaultValue={value}
                        className={`${st}__input`}
                    />

                    {img &&
                        <div className={`${st}__img-wrap`}>
                            <img src={img} alt={img} className={`${st}__img`}/>
                        </div>
                    }
                </div>

                {text &&
                    <span className={`${st}__label`}>{text}</span>
                }
            </div>
        );
    }
}

