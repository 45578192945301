import React from 'react';
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-image-lightbox/style.css';

import HomePage from "./views/HomePage";

function App() {
	return (
		<div className="App">
			<HomePage/>
		</div>
	);
}

export default App;
