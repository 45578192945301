import { css } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        [`
            input:-webkit-autofill, 
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus, 
            textarea:-webkit-autofill, 
            textarea:-webkit-autofill:hover, 
            textarea:-webkit-autofill:focus, 
            select:-webkit-autofill, 
            select:-webkit-autofill:hover, 
            select:-webkit-autofill:focus
        `]: {
            WebkitTextFillColor: colors.dark
        },

        'input:-internal-autofill-previewed, input:-webkit-autofill::first-line': {
            fontFamily: styles.fontFamily.bebasneue,
            ...fonts.p2,
        },

        '&__label': {
            ...fonts.p4,
            color: colors.dark,
            display: 'block',
            marginBottom: grid.default[1],
    
            '&._error': {

            }
        },
        '&__input': {
            ...fonts.placeholder,
            display: 'block',
            backgroundColor: 'transparent',
            boxSizing: 'border-box',
            resize: 'none',
            width: '100%',
            height: [40],
            ...fonts.p2,
            color: colors.dark,
            fontFamily: styles.fontFamily.bebasneue,
            paddingBottom: grid.default[0],
            borderBottom: `3px solid ${colors.dark}`,

            '::-webkit-input-placeholder': {
                ...fonts.p2,
                color: colors.gray2
            },
            '::-moz-placeholder': {
                ...fonts.p2,
                color: colors.gray2
            },
            ':-ms-input-placeholder': {
                ...fonts.p2,
                color: colors.gray2
            },
            ':-moz-placeholder': {
                ...fonts.p2,
                color: colors.gray2
            },

            // ':placeholder': {
            //     opacity: 0.3
            // },
            //
            // '::-moz-placeholder': {
            //     lineHeight: ['70px', '60px', null, null, '51px']
            // },
            
            '&:hover': {
                borderBottomColor: colors.d2
            },

            '&._error': {
                borderBottomColor: colors.carrot
            },

            '&._valid': {
                borderBottomColor: colors.yellow
            }
        }
    })
);
