import React, { Component } from 'react';
import { default as st } from './style';
import InView from "react-intersection-observer";
import TitleBlock from "../../components/TitleBlock";
import Question from "../../components/Question";
import MediaQuery from "react-responsive";
import styles from "../../constants/styles";

export default class Faq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeQuestion: null
        }
    }

    renderList = (list) => {
        const { activeQuestion } = this.state;

        return (
            list.map((item, key) => {
                const isActive = key === activeQuestion;

                return (
                    <Question key={key} active={isActive} clickHandler={() => {this.toggleQuestions(key)}} data={item}/>
                )
            })
        )
    }

    toggleQuestions = (index) => {
        const
            { activeQuestion } = this.state,
            newIndex = activeQuestion === index ? null : index;

        this.setState({
            activeQuestion: newIndex
        })
    }

    render() {
        const { title, list, bg, anchor='faq' } = this.props;

        return (
            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div id={anchor} ref={ref} className={`${st}${inViewMod}`}>
                            {bg &&
                                <MediaQuery minWidth={`${styles.breakpoints[1000]+1}px`}>
                                    <InView triggerOnce={true} rootMargin={'500px 0px'}>
                                        {({ inView, ref }) => (
                                            <div ref={ref} className={`${st}__bg animate-img`}>
                                                {inView && <img src={bg} alt={bg} className={`${st}__bg-img`} />}
                                            </div>
                                        )}
                                    </InView>
                                </MediaQuery>
                            }

                            <div className={`${st}__container container`}>
                                <div className={`${st}__inner-container inner-container`}>
                                    <div className={`${st}__table grid-table`}>
                                        <div className={`${st}__col ${st}__col_left col _3-10`}>
                                            <div className={'left-col-container'}>
                                                <TitleBlock
                                                    title={title}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${st}__col ${st}__col_right col _5-10`}>
                                            {list &&
                                                this.renderList(list)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>
        );
    }
}

