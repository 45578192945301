import React, { Component } from 'react';
import { default as st } from './style';
import InView from "react-intersection-observer";
import TitleBlock from "../../components/TitleBlock";
import FormDefault from "../../containers/FormDefault";
import MediaQuery from "react-responsive";
import styles from "../../constants/styles";
import ClosePopupButton from "../ClosePopupButton";

export default class FormPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        }

        this.timer = null;
    }

    onChange = (show) => {
        const delay = show ? 1000 : 0;

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.setState({
                active: show
            })
        }, delay)
    }

    render() {
        const { title, mod='', formMod='', buttonMod='', ...rest } = this.props;


        return (
            <InView onChange={this.onChange}>
                {({ inView, ref }) => {
                    const
                        { active } = this.state,
                        inViewMod = active ? ' _in-view' : '';

                    return (
                        <div ref={ref} className={`${st}${mod}${inViewMod}`}>
                            <MediaQuery maxWidth={`${styles.breakpoints[500]}px`}>
                                <ClosePopupButton />
                            </MediaQuery>

                            <div className={`${st}__table`}>
                                <div className={`${st}__col ${st}__col_left`}>
                                    <div className={'left-col-container'}>
                                        <TitleBlock title={title} />
                                    </div>
                                </div>

                                <div className={`${st}__col ${st}__col_right`}>
                                    <FormDefault {...rest} inViewMod={inViewMod} formMod={formMod} buttonMod={buttonMod} closeBtn={true} />
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>

        );
    }
}

