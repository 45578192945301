import React, { Component } from 'react';
import { default as st } from './style';

export default class Radio extends Component {
    constructor(props) {
        super(props);

        this.input = React.createRef();
    }

    onChange = (e, setVal) => {
        const { title, text, name, value, calc_type, callback } = this.props;

        if (callback) callback({
            title: title,
            text: text,
            name: name,
            val: setVal || (value || 'remove'),
            type: calc_type
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { disable } = this.props;

        if (disable) this.input.current.checked = false;
    }

    render() {
        const
            { text, name, value, icon, disable, mod='' } = this.props,
            iconMod = icon ? ' _icon' : '';

        return (
            <div className={`${st}${iconMod}${mod}`} >
                <div className={`${st}__input-wrap`} >
                    <input
                        ref={this.input}
                        onClick={this.onChange}
                        type='radio'
                        name={name}
                        defaultValue={value}
                        className={`${st}__input`}
                        disabled={disable}
                    />

                    <div className={`${st}__indicator`}>
                        {icon &&
                            <span className={`${st}__icon ${icon}`}/>
                        }

                        {text &&
                            <span className={`${st}__label`}>{text}</span>
                        }
                    </div>

                </div>
            </div>
        );
    }
}

