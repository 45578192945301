import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        padding: [7],
        background: 'rgba(255, 255, 255, .1)',

        '&__input-wrap': {
            position: 'relative',
        },

        '&__input': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: 2,
        },

        '&__input:checked ~ &__indicator, &__input:not(:disabled):hover ~ &__indicator': {
            background: colors.yellow,
            color: colors.dark,
        },

        '&__input:checked ~ &__indicator &__icon, &__input:hover ~ &__indicator &__icon': {
            '&::before': {
                color: colors.dark,
            }
        },

        '&__input:disabled ~ &__indicator': {
            color: 'rgba(255,255,255,.2)',
            pointerEvents: 'none'
        },

        '&__input:disabled': {
            pointerEvents: 'none'
        },

        '&__indicator': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: [46, 42, null, null, 31],
            padding: ['5px 20px'],
            color: colors.white,
            transition: styles.transitions.default,
            zIndex: 1,
        },

        '&__label': {
            ...fonts.b1,
        },

        '&__icon': {
            '&::before': {
                fontSize: [52],
                color: colors.yellow,
                transition: styles.transitions.default,
            }
        },

        '&._icon': {
            width: [129]
        },

        '&._icon &': {
            '&__indicator': {
                height: 'auto',
                padding: [11],
            },

            '&__label': {
                ...fonts.p4,
                textTransform: 'none',
                letterSpacing: 0,
                marginTop: [11]
            },
        }
    })
);
