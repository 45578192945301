import React from "react";
import urls from "../../constants/urls";
import fetchQuery from "../../utils/fetch";
import isNotEmpty from "../../utils/is-not-empty";

import Intro from "../../containers/Intro";
import Advantages from "../../containers/Advantages";
import About from "../../containers/About";
import Portfolio from "../../containers/Portfolio";
import Steps from "../../containers/Steps";
import Calculator from "../../containers/Calculator";
import Faq from "../../containers/Faq";
import Feedback from "../../containers/Feedback";
import Instagram from "../../containers/Instagram";
import Contacts from "../../containers/Contacts";

import Header from "../../containers/Header";
import Popup from "../../containers/Popup";

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phones_list: {},
            header: {},
            intro: {},
            advantages: {},
            about: {},
            portfolio: {},
            steps: {},
            calculator: {},
            faq: {},
            feedback: {},
            instagram: {},
            contacts: {},
            forms: {}
        };
    }

    componentDidMount() {
        return fetchQuery.bind(this)({url: urls.homepage});
    }

    render() {
        const {
            phones_list,
            header,
            intro,
            advantages,
            about,
            portfolio,
            steps,
            calculator,
            faq,
            feedback,
            instagram,
            contacts,
            forms
        } = this.state;

        return (
            <React.Fragment>
                {isNotEmpty(header) &&
                    <Header {...header} phones_list={phones_list}/>
                }

                {isNotEmpty(intro) &&
                    <Intro {...intro}/>
                }


                {isNotEmpty(portfolio) &&
                    <Portfolio {...portfolio}/>
                }


                {isNotEmpty(calculator) &&
                    <Calculator {...calculator}/>
                }



                {isNotEmpty(steps) &&
                    <Steps {...steps}/>
                }


                {isNotEmpty(advantages) &&
                    <Advantages {...advantages}/>
                }

                {isNotEmpty(about) &&
                    <About {...about}/>
                }

                {isNotEmpty(feedback) && isNotEmpty(forms) &&
                    <Feedback {...feedback} form={forms.feedback}/>
                }

                {isNotEmpty(instagram) &&
                    <Instagram {...instagram}/>
                }

                {isNotEmpty(faq) &&
                    <Faq {...faq}/>
                }

                {isNotEmpty(contacts) &&
                    <Contacts {...contacts} phones_list={phones_list}/>
                }

                {isNotEmpty(forms) &&
                    <Popup {...forms}/>
                }
            </React.Fragment>
        )
    }
}

export default HomePage;


