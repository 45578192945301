import { css, keyframes } from 'emotion';
import { styles, fonts, grid } from '../../constants';
const { mq, colors } = styles;

const showSlides = keyframes`
    0% {
        opacity: 0;
        transform: translateY(50px)
    }
  
    100% {
        opacity: 1;
        transform: translateY(0)
    }
`;

export default css(
    mq({
        '&__list': {

        },

        '&__container': {
            display: 'block',
            position: 'relative',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: [null, null, null, null, 550, 300]
        },

        '&__btn-wrap': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 5,
            opacity: 0,
            transition: styles.transitions.default
        },

        '&__list > &__item, &__list.slick-initialized .slick-slide': {
            animation: `${showSlides} .3s ease both`,
            opacity: 0,
            ...styles.setDelays({delay: 100, count: 3, type: 'animation'})
        },

        '&__item': {
            position: 'relative',
            paddingTop: grid.default[6],
            paddingBottom: [grid.default[6][0], grid.default[6][1], grid.default[6][2], 20],
            paddingLeft: [50, 47, null, 15, 20],
            paddingRight: [50, 47, null, 15, 20],
            margin: [null, null, null, '0 10px'],
            cursor: 'pointer',
        },

        '&__item:hover &': {
            '&__title': {
                '&::after': {
                    opacity: 0
                }
            },

            '&__btn-wrap': {
                opacity: 1
            }
        },

        '&__img-wrap': {
            position: 'relative',
            overflow: 'hidden',
            marginBottom: grid.default[4],
            ...styles.rh(),
            width: '100%',
            zIndex: 3,
        },

        '&__img': {
            ...styles.of({fit: 'contain'}),
            ...styles.absolute(),
        },

        '&__count': {
            ...styles.absolute(0,'auto','auto',0),
            ...fonts.p1,
            color: colors.gray1,
            zIndex: 1,
            // lineHeight: .72
        },

        '&__title-wrap': {
            paddingTop: [20, 10],
            paddingLeft: 20,
            paddingRight: 20,
            textAlign: 'center',
            zIndex: 3,
        },

        '&__title' :{
            position: 'relative',
            display: 'inline-block',
            ...fonts.h3,

            '&::after': {
                content: '"\\e90a"',
                fontFamily: styles.fontFamily.icomoon,
                fontSize: 21,
                lineHeight: '1',
                color: colors.yellow,
                ...styles.absolute('auto', 'auto', '100%', '100%'),
                transition: styles.transitions.default
            }

        }
    })
);
