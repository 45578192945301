import React from "react";
import ProjectsSlider from "../../components/ProjectsSlider";
import ScrollWrap from "../../components/ScrollWrap";
import { default as st } from './style';
import { connect } from "react-redux";
import { RemoveScroll } from 'react-remove-scroll';
import TyPopupContent from "../../components/TyPopupContent";
import FormPopup from "../../components/FormPopup";

import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';


class Popup extends React.Component {
    renderPopupForm = (obj) => {
        const { data, ...rest } = obj;

        return (
            <FormPopup {...data} {...rest} />
        )
    }

    renderPortfolio = (obj) => {
        const { data } = obj;

        return (
            <ProjectsSlider list={data} />
        )
    };

    renderTy = (obj) => {
        const { data } = obj;

        return (
            <TyPopupContent {...data} />
        )
    };

    switchContent = (type, data, additionalFormData) => {
        switch (type) {
            case 'feedback':
                const { feedback } = this.props;

                return this.renderPopupForm({
                    data: feedback,
                    buttonMod: ' _left'
                });

            case 'calc':
                const { calc } = this.props;

                return this.renderPopupForm({
                    data: calc,
                    additionalFormData: additionalFormData,
                    mod: ' _wide',
                    formMod: ' _4-6-container',
                    buttonMod: ' _right'
                });

            case 'portfolio':
                return this.renderPortfolio({
                    data: data
                });

            case 'ty':
                return this.renderTy({
                    data: data
                });

            default: {}
        }
    }

    render() {
        const { show, type, data, additionalFormData } = this.props.popup;
        const showMod = show ? '_show' : '';

        return (
            <RemoveScroll enabled={show} removeScrollBar={false}>
                <div className={`${st} ${showMod}`}>
                    <div className={`${st}__container container`}>
                        <div className={`${st}__inner-container narrow-container`}>
                            <ScrollWrap>
                                <div className={`${st}__wrap`}>
                                    <div className={`${st}__content`}>
                                        <TransitionGroup className={`${st}__transition-group`}>
                                            <CSSTransition
                                                timeout={2000}
                                                // in={!!(show && type)}
                                                unmountOnExit
                                                key={type}
                                            >
                                                <div className={`${st}__animation-wrap`}>
                                                    { this.switchContent(type, data, additionalFormData) }
                                                </div>
                                            </CSSTransition>
                                        </TransitionGroup>
                                    </div>
                                </div>
                            </ScrollWrap>
                        </div>
                    </div>
                </div>
            </RemoveScroll>
        )
    }
}

const mapStateToProps = store => {
    return {
        popup: store.popup
    }
};

export default connect(mapStateToProps)(Popup);
