import { css, keyframes } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq, colors } = styles;

const floating = keyframes`
    0% {
        transform: translateY(0px) translateX(0px);
    }
    
    50% {
        transform: translateY(-10px) translateX(10px);
    }

    100% {
        transform: translateY(0px) translateX(0px);
    }
`;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        marginBottom: grid.default[8],

        '&__col': {
            '&_left': {
                paddingTop: [grid.default[5][0], grid.default[5][1], 0]
            },
        },

        '&__bg-wrap': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1
        },

        '&__stair': {
            position: 'relative',
            width: [530, 400, 359],
            height: [530, 400, 359],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,

            svg: {
                position: 'relative',
                display: 'block',
                width: '100%',
                height: '100%',
                strokeDasharray: 835,
                strokeDashoffset: 835,
                transition: '1s linear',
            },

            '&._draw': {
                svg: {
                    transition: '5s linear',
                    strokeDashoffset: 0
                },
            }
        },

        '&__shavings-wrap': {
            ...styles.absolute(0, [0, null, null, 'auto'], 0, [0, null, null, '85%']),
            width: [209, 171, 135],
            height: [186, 152, 120],
            zIndex: 2
        },

        '&._in-view &__shavings-wrap': {
            animation: `${floating} 3s ease-in-out infinite`,
        },

        '&__shavings': {
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',

            '&._load': {
                backgroundImage: `url(${require('../../images/bg/wood-shavings.png')})`,
            }
        },

        '&__list': {
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            zIndex: 2
        },

        '&__step': {
            position: 'relative',
            width: ['calc(100% / 7 * 2)', null, null, '50%', null, '100%'],
            marginBottom: grid.default[1],

            '&:nth-child(4)': {
                marginRight: ['calc(100% / 7 * 3)', null, null, 0]
            }
        },

        '&__num': {
            ...fonts.p1,
            color: colors.gray1
        },

        '&__step-title': {
            width: '100%',
            ...styles.absolute('auto', 'auto'),
            marginBottom: grid.default[4],
            ...fonts.p2,
            color: colors.dark,
            paddingLeft: [20],
            maxWidth: [275, null, null, 200, null, 'none'],

            '&::before': {
                content: '""',
                display: 'block',
                ...styles.absolute(0,'auto','auto'),
                width: [3],
                height: [20],
                background: colors.yellow
            }
        },
    })
);
