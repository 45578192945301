import { css } from 'emotion';
import { styles, grid } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        display: 'block',
        position: 'relative',
        marginBottom: grid.default[7],

        '&__col': {
            '&_right': {
                marginTop: [null, null, 60, null, 50],
            }
        },

        '&__aside-wrap': {
            maxWidth: [310, null, 'none']
        }
    })
);
