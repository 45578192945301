import React, { Component } from "react";
import TextBlock from "../TextBlock";
import ClosePopupButton from "../ClosePopupButton"
import { default as st } from "./style";
import Slider from "react-slick";
import ImgSlider from "../ImgSlider";
import { styles } from "../../constants";
import MediaQuery from "react-responsive";

export default class ProjectsSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSlide: 0,
            nav1: null,
            nav2: null
        };

        this.imgSlidersSettings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            speed: 1000,
            arrows: false,
            fade: false,
            centerPadding: "0",
            draggable: false,
            accessibility: false,
            focusOnSelect: false,
            swipe: false,
            swipeToSlide: false,
            touchMove: false,
        };

        this.textSliderSettings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            speed: 1000,
            arrows: false,
            fade: false,
            centerPadding: "0"
        };
    }

    renderCounter = (count) => {
        const { currentSlide } = this.state;

        return (
            <div className={`${st}__counter`}>
                <div className={`${st}__arrows-wrap`}>
                    <div className={`${st}__arrow ${st}__arrow_prev`} onClick={() => {this.slider1.slickPrev();}} />
                    <div className={`${st}__arrow ${st}__arrow_next`} onClick={() => {this.slider1.slickNext();}} />
                </div>

                <div className={`${st}__counts`}>
                    <div className={`${st}__current`}>
                        {currentSlide + 1}
                    </div>

                    <div className={`${st}__total`}>
                        {`/ ${count}`}
                    </div>
                </div>


            </div>
        )
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    renderImgSlides = (list) => {
        return list.map(({images}, key) => <ImgSlider key={key} list={images} />)
    }

    renderTextSlides = (list) => {
        return (
            list.map(({title, subtitle, content}, key) => {
                return (
                    <div key={key} className={`${st}__text-slide`}>
                        {title &&
                            <h3 className={`${st}__title`}>
                                {title}
                            </h3>
                        }

                        {subtitle &&
                            <p className={`${st}__subtitle`}>
                                {subtitle}
                            </p>
                        }

                        {content &&
                            <div className={`${st}__text-wrap`}>
                                <TextBlock content={content} mod={' _clear-margins'} />
                            </div>
                        }
                    </div>
                )
            })
        )
    }

    renderImgSlider = (list) => {
        return (
            list.length > 1
                ?
                    <Slider
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className={`${st}__img-sliders`}
                        {...this.imgSlidersSettings}
                    >
                        {
                            this.renderImgSlides(list)
                        }
                    </Slider>
                :
                    <div className={`${st}__img-sliders`}>
                        {
                            this.renderImgSlides(list)
                        }
                    </div>
        )
    }

    renderTextSlider = (list) => {
        return (
            list.length > 1
                ?
                    <Slider
                        beforeChange={(oldIndex, newIndex) => this.setState({ currentSlide: newIndex })}
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        className={`${st}__text-list`}
                        {...this.textSliderSettings}
                    >
                        {
                            this.renderTextSlides(list)
                        }
                    </Slider>
                :
                    <div className={`${st}__text-list`}>
                        {
                            this.renderTextSlides(list)
                        }
                    </div>
        )
    }

    render() {
        const
            { list } = this.props,
            showCounter = list && list.length > 1;

        return (
            <div className={`${st}`}>
                <ClosePopupButton />

                <div className={`${st}__table`}>
                    <div className={`${st}__col ${st}__col_left`}>
                        {list &&
                           this.renderImgSlider(list)
                        }
                    </div>

                    {showCounter &&
                        <MediaQuery maxWidth={`${styles.breakpoints[700]}px`}>
                            <div className={`${st}__counter-wrap`}>
                                {list &&
                                this.renderCounter(list.length)
                                }
                            </div>
                        </MediaQuery>
                    }

                    <div className={`${st}__col ${st}__col_right`}>
                        {list &&
                            this.renderTextSlider(list)
                        }

                        {showCounter &&
                            <MediaQuery minWidth={`${styles.breakpoints[700]+1}px`}>
                                <div className={`${st}__counter-wrap`}>
                                    {list &&
                                        this.renderCounter(list.length)
                                    }
                                </div>
                            </MediaQuery>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

