import React, { Component } from 'react';
import { default as st } from './style';
import InView from "react-intersection-observer";
import TitleBlock from "../../components/TitleBlock";
import FormDefault from "../FormDefault";
import {ReactComponent as StairSvg} from "../../images/bg/stair2.svg";
import styles from "../../constants/styles";
import MediaQuery from "react-responsive";

export default class Feedback extends Component {
    render() {
        const { title, subtitle, form } = this.props;

        return (
            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div ref={ref} className={`${st}${inViewMod}`}>
                            <div className={`${st}__container container`}>
                                <div className={`${st}__inner-container inner-container`}>
                                    <div className={`${st}__table grid-table`}>
                                        <div className={`${st}__col ${st}__col_left col _3-10`}>
                                            <div className={'left-col-container'}>
                                                <TitleBlock title={title} subtitle={subtitle} />
                                            </div>
                                        </div>
                                        <div className={`${st}__col ${st}__col_right col _6-10`}>
                                            <MediaQuery minWidth={`${styles.breakpoints[500]+1}px`}>
                                                <InView threshold={0.5} >
                                                    {({ inView, ref }) => {
                                                        const imgMod = inView ? ' _draw' : '';

                                                        return (
                                                            <div ref={ref} className={`${st}__bg-wrap`}>
                                                                <div className={`${st}__stair${imgMod}`}>
                                                                    <StairSvg />
                                                                </div>
                                                            </div>
                                                        )
                                                    }}
                                                </InView>
                                            </MediaQuery>

                                            <div className={`${st}__form-wrap`}>
                                                <FormDefault {...form} inViewMod={inViewMod} buttonMod={' _right'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>
        );
    }
}

