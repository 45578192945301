import React, { Component } from 'react';
import { default as st } from './style';
import {resetCalcData} from "../../actions/setCalcData";
import {connect} from "react-redux";

class ResetButton extends Component {
    onClick = () => {
        const { resetCalcData } = this.props;

        resetCalcData();
    }

    render() {
        const { text } = this.props;

        return (
            <div className={`${st}`}>
                <span className={`${st}__text`} onClick={this.onClick}>{text}</span>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        resetCalcData: obj => dispatch(resetCalcData())
    }
};


export default connect(null, mapDispatchToProps)(ResetButton);