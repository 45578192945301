import { css } from 'emotion';
import { styles, grid, fonts } from '../../constants';
const { mq } = styles;

export default css(
    mq({
        '&__list': {
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: 'calc(100% + 20px)',
            left: '-10px'
        },

        '&__item': {
            position: 'relative',
            marginBottom: grid.default[3],
            paddingLeft: 23,
            width: ['calc(100% / 3 - 20px)', 'calc(50% - 20px)', null, null, null, 'calc(100% - 20px)'],
            marginLeft: '10px',
            marginRight: '10px',

            ':before': {
                content: '""',
                ...styles.absolute(0, 'auto', 'auto'),
                width: 3,
                height: 20,
                background: styles.colors.yellow
            }
        },

        '&__text': {
            position: 'relative',
            display: 'block',
            maxWidth: 275,
            marginRight: 'auto',
            ...fonts.p3,
        }
    })
);
