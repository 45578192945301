import React, { Component } from 'react';
import { default as st } from './style';
import TitleBlock from "../../components/TitleBlock";
import InView from "react-intersection-observer";
import styles from "../../constants/styles";
import MediaQuery from 'react-responsive';
import CalcTotal from "../../components/CalcTotal";
import CalculatorFields from "../CalculatorFields";
import ResetButton from "../../components/ResetButton";

export default class Calculator extends Component {
    constructor(props) {
        super(props);

        this.table = React.createRef();
        this.totalWrap = React.createRef();
    }

    render() {
        const { anchor='calculator', title, rows, total, reset } = this.props;

        return (
            <InView rootMargin={'-20% 0% -20%'}>
                {({ inView, ref }) => {
                    const inViewMod = inView ? ' _in-view' : '';

                    return (
                        <div id={anchor} ref={ref} className={`${st}${inViewMod}`}>
                            <div className={`${st}__container container`}>
                                <div className={`${st}__inner-container inner-container`}>
                                    <MediaQuery maxWidth={`${styles.breakpoints[1300]}px`}>
                                        <div className={'left-col-container'}>
                                            <TitleBlock title={title} mod={' _white'} />
                                        </div>
                                    </MediaQuery>

                                    <div ref={this.table} className={`${st}__table grid-table`}>
                                        <div className={`${st}__col ${st}__col_left col _3-10`}>
                                            <MediaQuery minWidth={`${styles.breakpoints[1300]+1}px`}>
                                                <div className={'left-col-container'}>
                                                    <TitleBlock title={title} mod={' _white'} />
                                                </div>
                                            </MediaQuery>

                                            <MediaQuery maxWidth={`${styles.breakpoints[1800]}px`} minWidth={`${styles.breakpoints[1300]+1}px`}>
                                                <div ref={this.totalWrap} className={`${st}__total-wrap`}>
                                                    {<CalcTotal totalWrap={this.totalWrap} table={this.table} {...total} inViewMod={inViewMod}/>}
                                                </div>
                                            </MediaQuery>

                                            <MediaQuery maxWidth={`${styles.breakpoints[1300]}px`}>
                                                <CalcTotal totalWrap={this.totalWrap} table={this.table} {...total} inViewMod={inViewMod}/>
                                            </MediaQuery>

                                            <MediaQuery maxWidth={`${styles.breakpoints[1800]}px`} minWidth={`${styles.breakpoints[1300]+1}px`}>
                                                <div className={`${st}__reset-wrap`}>
                                                    <ResetButton text={reset} />
                                                </div>
                                            </MediaQuery>
                                        </div>

                                        <div className={`${st}__col ${st}__col_right col _7-10`}>
                                            <div className={`${st}__rows-container`}>
                                                <CalculatorFields {...rows} />
                                            </div>

                                            <MediaQuery minWidth={`${styles.breakpoints[1800]+1}px`}>
                                                <div className={`${st}__total-container`}>
                                                    <div ref={this.totalWrap} className={`${st}__total-wrap`}>
                                                        {<CalcTotal totalWrap={this.totalWrap} table={this.table} {...total} inViewMod={inViewMod} />}
                                                    </div>

                                                    <div className={`${st}__reset-wrap`}>
                                                        <ResetButton text={reset} />
                                                    </div>
                                                </div>
                                            </MediaQuery>

                                            <MediaQuery maxWidth={`${styles.breakpoints[1300]}px`}>
                                                <ResetButton text={reset} />
                                            </MediaQuery>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </InView>
        );
    }
}

