import { styles } from './';

const { breakpoints } = styles,
    base = [
        {
            type: 'default',
            values: [
                {
                    numbers: [
                        10, //0
                        20, //1
                        30, //2
                        40, //3
                        50, //4
                        60, //5
                        70, //6
                        100, //7
                        150, //8
                        80, //9
                    ]
                },
                {
                    point: breakpoints['1800'],
                    numbers: [
                        null, //0
                        null, //1
                        null, //2
                        null, //3
                        null, //4
                        null, //5
                        null, //6
                        null, //7
                        null, //8
                        null, //9
                    ]
                },
                {
                    point: breakpoints['1300'],
                    numbers: [
                        null, //0
                        null, //1
                        null, //2
                        null, //3
                        null, //4
                        null, //5
                        null, //6
                        null, //7
                        120, //8
                        null, //9
                    ]
                },
                {
                    point: breakpoints['900'],
                    numbers: [
                        null, //0
                        null, //1
                        null, //2
                        null, //3
                        null, //4
                        null, //5
                        null, //6
                        null, //7
                        null, //8
                        null, //9
                    ]
                },
                {
                    point: breakpoints['700'],
                    numbers: [
                        null, //0
                        null, //1
                        null, //2
                        30, //3
                        40, //4
                        50, //5
                        null, //6
                        60, //7
                        90, //8
                        60, //9
                    ]
                }
            ]
        }
    ];

let grid_generate = function(obj) {
    let { negative } = obj || {},
        grid = {};

    for (let i = 0; i < base.length; i++) {
        let { type, values } = base[i];

        grid[type] = [];

        for (let j = 0; j < values.length; j++) {
            let { numbers } = values[j];

            for (let k = 0; k < numbers.length; k++) {
                if (!grid[type][k]) {
                    grid[type][k] = [];
                }

                if (negative) {
                    grid[type][k].push(
                        numbers[k] ? numbers[k] * -1 : numbers[k]
                    );
                } else {
                    grid[type][k].push(numbers[k]);
                }
            }
        }
    }

    return grid;
};

const grid = grid_generate();
export const grid_negative = grid_generate({ negative: true });

export default grid;
