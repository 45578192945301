import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        marginBottom: [null, 20],
        cursor: 'pointer',

        '&__wrap': {
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
            padding: ['9px 11px 9px 13px', '10px'],
        },

        ':before': {
            content: '""',
            ...styles.absolute(['0', 'auto'], ['auto', 0], [0, 'calc(100% + 7px)']),
            width: [3, 20],
            height: [20, 3],
            background: styles.colors.yellow,
            transition: 'opacity .4s ease'
        },

        '&:first-of-type': {
            ':before': {
                display: 'none'
            },
        },

        '&__text': {
            position: 'relative',
            display: 'block',
            ...fonts.menu,
            color: styles.colors.dark,
            zIndex: 2,
        },

        '&__circle': {
            position: 'absolute',
            display: 'block',
            borderRadius: '50%',
            transform: 'translate(-50%,-50%)',
            backgroundColor: colors.yellow,
            transition: 'width 1s ease-in-out, height 1s ease-in-out, top .1s ease, left .1s ease',
            width: 0,
            zIndex: 1,

            ':after': {
                content: "''",
                display: 'block',
                paddingTop: '100%'
            }
        },

        ':hover': {
            '& + *': {
                ':before': {
                    opacity: 0
                },
            }
        },

        ':hover &': {
            '&__circle': {
                transition: 'width .4s ease-in-out, height .6s ease-in-out',
                width: '220%'
            }
        },
    })
);
