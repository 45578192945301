import { css } from 'emotion';
import { styles, fonts } from '../../constants';
const { mq, colors } = styles;

export default css(
    mq({
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        overflow: 'hidden',
        transition: 'border-color .3s ease,color .3s ease',
        backgroundColor: colors.yellow,
        height: [56, null, null, null, 50],
        width: [null, null, null, null, null, '100%'],
        cursor: 'pointer',
        color: colors.dark,

        '&__wrapper': {
            ...fonts.b1,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: ['5px 40px', null , null , null , '5px 20px'],
            textAlign: 'center',
            color: 'inherit'
        },

        '&__circle': {
            position: 'absolute',
            display: 'block',
            borderRadius: '50%',
            transform: 'translate(-50%,-50%)',
            backgroundColor: colors.y2,
            transition: 'width 0.4s ease-in-out, height 0.4s ease-in-out',
            width: 0,

            ':after': {
                content: "''",
                display: 'block',
                paddingTop: '100%'
            }
        },

        '&__parallax-wrap': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            zIndex: 1,
        },

        '&__shadow-wrap': {
            ...styles.absolute(),
            width: '100%',
            height: '100%',
            zIndex: 1,
            opacity: 0,
            transition: 'opacity 2s ease'
        },

        '&__shadow': {
            width: '100%',
            height: '100%',
            background: colors.yellow,
            filter: 'blur(10px)',
            pointerEvents: 'none'
        },

        '&._bordered': {
            height: [45],
            border: `3px solid ${colors.yellow}`,
            backgroundColor: 'transparent'
        },

        '&._bordered &': {
            '&__circle': {
                backgroundColor: colors.yellow
            },
            '&__wrapper': {
                padding: ['5px 20px'],
            },
        },

        '&._shadow': {
            border: 'none',
            overflow: 'visible',
            pointerEvents: 'auto !important'
        },

        '&._shadow &': {
            '&__wrapper': {
                backgroundColor: colors.yellow,
                zIndex: 2
            },
        },

        '&._shadow:hover &': {
            '&__shadow-wrap': {
                transition: styles.transitions.default,
                opacity: 1,
            },
        },

        '&:not(._shadow):hover &': {
            '&__circle': {
                transition: 'width .4s ease-in-out, height .6s ease-in-out',
                width: '220%'
            }
        },
    })
);
